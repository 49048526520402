/* eslint-disable no-shadow */
import { HoraHelper } from '../helpers/HoraHelper';
import Validation from '../helpers/ValidationHelper';

export const length = {
  validate(value: string, args: any) {
    return Validation.ValidadeLength(value, args.length);
  },
  params: ['length'],
  message: 'O campo {_field_} deve conter {length} caracteres',
};

export const cpf = {
  validate(value: string) {
    return Validation.ValidateCPF(value);
  },
  message: 'O campo {_field_} deve conter um CPF válido',
};

export const cnpj = {
  validate(value: string) {
    return Validation.ValidateCNPJ(value);
  },
  message: 'O campo {_field_} deve conter um CNPJ válido',
};

export const cnpjDisponivel = {
  params: ['documentoJaUtilizado'],
  validate(value: string, args: any) {
    if (args.documentoJaUtilizado === 'true' && value.replace(/\D/g, '').length === 14) {
      return false;
    }

    return true;
  },
  message: 'O CNPJ já está em uso',
};

export const cpfDisponivel = {
  params: ['documentoJaUtilizado'],
  validate(value: string, args: any) {
    if (args.documentoJaUtilizado === 'true' && value.replace(/\D/g, '').length === 11) {
      return false;
    }

    return true;
  },
  message: 'O CPF já está em uso',
};

export const codigoSetor = {
  validate(value: string) {
    return Validation.ValidateCodigoSetor(value);
  },
  message: 'O campo {_field_} deve conter um código válido',
};

export const tempoMenorQue = {
  params: ['target'],
  validate(value: string, param): boolean {
    const inicio = HoraHelper.stringHoraParaMinutos(value || '00:00');
    const fim = HoraHelper.stringHoraParaMinutos(param.target || '00:00');
    const valido = inicio < fim;
    return valido;
  },
  message: 'O campo {_field_} deve ser anterior a {target}',
};

export const tempoMaiorQue = {
  params: ['target'],
  validate(value: string, param): boolean {
    const inicio = HoraHelper.stringHoraParaMinutos(value || '00:00');
    const fim = HoraHelper.stringHoraParaMinutos(param.target || '00:00');
    const valido = inicio > fim;
    return valido;
  },
  message: 'O campo {_field_} deve ser posterior a {target}',
};
