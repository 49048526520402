import { IEstadoMunicipioIBGE } from '../EstadoMunicipioIBGE/EstadoMunicipioIBGE';
import { IPais } from '../Pais/Pais';
import { ITipoLogradouro } from '../TipoLogradouro/TipoLogradouro';

export interface IEndereco {
  cep: string;
  rua?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  regiao?: string;
  estado: number | null;
  uf: number | string;
  paisId?: number | null;
  pais?: IPais;
  codigoPostalPais?: string | null;
  estadosMunicipiosIBGEId?: number | string;
  estadosMunicipiosIBGE ?: IEstadoMunicipioIBGE;
  tipoLogradouroId?: number | null;
  tipoLogradouro?: ITipoLogradouro;
}

export default class Endereco implements IEndereco {
  public cep: string;

  public rua?: string;

  public numero?: string;

  public complemento?: string;

  public bairro?: string;

  public cidade?: string;

  public regiao?: string;

  public estado: number | null;

  public uf: number | string;

  public paisId?: number | null;

  public pais?: IPais;

  public codigoPostalPais?: string | null;

  public estadosMunicipiosIBGEId?: number | string;

  public estadosMunicipiosIBGE?: IEstadoMunicipioIBGE;

  public tipoLogradouroId?: number | null;

  public tipoLogradouro?: ITipoLogradouro;

  constructor() {
    this.cep = '';
    this.estado = 0;
    this.uf = '';
    this.tipoLogradouroId = null;
  }
}
