export interface IRedirecionamentoFiltro {
  pageSize: number;
}

export default class RedirecionamentoFiltro implements RedirecionamentoFiltro {
  public pageSize: number;

  constructor() {
    this.pageSize = 5;
  }
}
