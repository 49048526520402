export default class ValidationHelper {
  /**
 * Valida se o comprimento de uma string é igual a um número especificado.
 *
 * @param {string} value - A string a ser validada.
 * @param {string} len - O comprimento esperado da string.
 * @returns {boolean} Verdadeiro se o comprimento da string for igual ao número especificado, caso contrário, falso.
 */
  public static ValidadeLength(value: string, len: string): boolean {
    return value.length === Number(len);
  }


  /**
 * Valida um CPF.
 *
 * @param {string} cpf - O CPF a ser validado.
 * @returns {boolean} Verdadeiro se o CPF for válido, caso contrário, falso.
 */
  public static ValidateCPF(cpf: string): boolean {
    if (!cpf) return false;

    const cpfNormalizado = cpf.replace(/\D/g, '');

    if (cpfNormalizado.length !== 11) return false;

    if (cpfNormalizado.split('').every((el) => el === cpfNormalizado[0])) return false;

    let somaDv1 = 0;
    let somaDv2 = 0;

    for (let posicao = 0; posicao < 9; posicao += 1) {
      const digito = parseInt(cpfNormalizado[posicao], 10);
      somaDv1 += digito * (10 - posicao);
      somaDv2 += digito * (11 - posicao);
    }

    let restoDv1 = somaDv1 % 11;
    restoDv1 = restoDv1 < 2 ? 0 : (11 - restoDv1);

    somaDv2 += restoDv1 * 2;
    let restoDv2 = somaDv2 % 11;
    restoDv2 = restoDv2 < 2 ? 0 : (11 - restoDv2);

    if (parseInt(cpfNormalizado[9], 10) !== restoDv1
      || parseInt(cpfNormalizado[10], 10) !== restoDv2) {
      return false;
    }

    return true;
  }

  /**
 * Valida um CNPJ.
 *
 * @param {string} cnpj - O CNPJ a ser validado.
 * @returns {boolean} Verdadeiro se o CNPJ for válido, caso contrário, falso.
 */
  public static ValidateCNPJ(cnpj: string): boolean {
    if (!cnpj) return false;

    const cnpjNormalizado = cnpj.replace(/\D/g, '');

    if (cnpjNormalizado.length !== 14) return false;

    if (cnpjNormalizado.split('').every((el) => el === cnpjNormalizado[0])) return false;

    const multiplicador1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const multiplicador2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

    let soma1 = 0;
    let soma2 = 0;
    for (let i = 0; i < 12; i += 1) {
      const digito = parseInt(cnpjNormalizado[i], 10);
      soma1 += digito * multiplicador1[i];
      soma2 += digito * multiplicador2[i];
    }

    let resto = (soma1 % 11);
    resto = resto < 2 ? 0 : (11 - resto);

    const digitoVerificador1 = resto;
    soma2 += resto * multiplicador2[12];

    resto = (soma2 % 11);
    resto = resto < 2 ? 0 : (11 - resto);

    const digitoVerificador2 = resto;

    if (parseInt(cnpjNormalizado[12], 10) !== digitoVerificador1
      || parseInt(cnpjNormalizado[13], 10) !== digitoVerificador2) {
      return false;
    }

    return true;
  }

  /**
 * Valida um código de setor.
 *
 * @param {string} code - O código de setor a ser validado.
 * @returns {boolean} Verdadeiro se o código de setor não começar com 'esocial', caso contrário, falso.
 */
  public static ValidateCodigoSetor(code: string): boolean {
    return !code.trim().toLowerCase().startsWith('esocial');
  }
}
