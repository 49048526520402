import Vue from 'vue';

export default class NotificationHelper {
  /**
   * Opções padrão para exibição de notificações.
   */
  public static options = {
    position: 'top-right',
    duration: 3000,
    keepOnHover: true,
    action: [
      {
        icon: 'fas fa-times-circle fa-3x',
        onClick: (_: any, toastObject: any) => {
          toastObject.goAway(0);
        },
      },
    ],
    containerClass: 'toast__container',
    iconPack: 'custom-class',
    icon: {
      name: '',
    },
    closeOnSwipe: true,
    singleton: true,
  };

  /**
   * Exibe uma notificação de sucesso.
   *
   * @param {string} message - O texto da mensagem de sucesso.
   */
  public static success(message: string) {
    const { options } = NotificationHelper;
    options.icon = {
      name: 'fas fa-check-circle fa-2x',
    };

    Vue.toasted.success(message, options as any);
  }

  /**
     * Exibe uma notificação de aviso.
     *
     * @param {string} message - O texto da mensagem de aviso.
     */
  public static warning(message: string) {
    const { options } = NotificationHelper;
    options.icon = {
      name: 'fa-solid fa-exclamation fa-2x',
    };

    Vue.toasted.info(message, options as any);
  }

  /**
   * Exibe uma notificação de erro.
   *
   * @param {any} message - A mensagem de erro a ser exibida.
   */
  public static error(message: any) {
    const { options } = NotificationHelper;
    options.icon = {
      name: 'fas fa-exclamation-circle fa-2x',
    };

    if (message === 'Nenhum registro encontrado.') {
      return;
    }

    if (typeof message === 'string') {
      Vue.toasted.error(message, options as any);
    } else if (message.message) {
      Vue.toasted.error(message.message, options as any);
    } else if (message.errors) {
      Object.keys(message.errors).forEach((key) => {
        const text = message.errors[key].join('<br />');
        Vue.toasted.error(text, options as any);
      });
    }
  }

  /**
 * Cria um atraso por um período de tempo especificado.
 *
 * @param {number} tempoEmSegundos - O tempo de atraso em segundos.
 * @returns {Promise<void>} - Uma promessa que é resolvida após o tempo de atraso especificado.
 *
 * @example
 * // Usa o método para criar um atraso de 2 segundos
 * await this.atrasoPorTempo(2);
 */
  public static atrasoPorTempo(tempoEmSegundos: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, (tempoEmSegundos * 1000)));
  }
}
