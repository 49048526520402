import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';

import { IAuthModel } from '../../models/Auth/AuthModel';
import AuthUser, { IAuthUserModel } from '../../models/Auth/AuthUserModel';
import AutenticacaoService from '../../services/AutenticacaoService';
import {
  decode,
  getToken,
  removeToken,
  setToken,
} from '../../utils/token';
import store from '..';
import { AUTH_LOGIN, AUTH_LOGOUT, GET_ID_BY_AUTH } from '../actions/AutenticacaoActions';

export interface IAuthState {
  token: string;
  _mostrarSenha: boolean;
  _mostrarConfirmaSenha: boolean;
  user: IAuthUserModel;
}

@Module({
  name: 'auth',
  namespaced: true,
  dynamic: true,
  store,
})
class AuthModule extends VuexModule implements IAuthState {
  private _token = getToken();

  public _mostrarSenha = false;

  public _mostrarConfirmaSenha = false;

  private _user = decode(this._token);

  private _loading = false;

  get token(): string {
    return this._token;
  }

  get user(): IAuthUserModel {
    return this._user;
  }

  get authenticated(): boolean {
    return !!this._token;
  }

  get loading(): boolean {
    return this._loading;
  }

  @Mutation
  public exibirSenha(reset = false) {
    this._mostrarSenha = reset ? false : !this._mostrarSenha;
  }

  @Mutation
  public exibirConfirmaSenha(reset = false) {
    this._mostrarConfirmaSenha = reset ? false : !this._mostrarConfirmaSenha;
  }

  @Mutation
  private SET_TOKEN_M(token: string) {
    this._token = token;
  }

  @Mutation
  private SET_AUTH_USER_M() {
    if (this._token) {
      this._user = decode(this._token);
    } else {
      this._user = new AuthUser();
    }
  }

  @Mutation
  private GET_AUTH_USER_M() {
    let decodedToken = decode(this._token);
    if (this._token) {
      decodedToken = this._user;
    }

    return this._user.id;
  }


  @Mutation
  private START_LOADING_M() {
    this._loading = true;
  }

  @Mutation
  private STOP_LOADING_M() {
    this._loading = false;
  }

  @Action({ rawError: true })
  public async [AUTH_LOGIN](authObject: IAuthModel) {
    return new Promise((resolve, reject) => {
      if (window.localStorage.getItem('IsLogged')?.includes('true')) {
        const error = 'Já existe uma conta conectada no sistema!';
        reject(error);
      }
      this.START_LOADING_M();

      AutenticacaoService.authenticate(authObject)
        .then((data: any) => {
          const token = data.accessToken;

          if (window.localStorage.getItem('IsLogged')?.includes('false')) {
            window.localStorage.setItem('IsLogged', 'true');
          }

          window.localStorage.setItem('fotoProfile', data.urlFotoDoPerfil);

          this.exibirSenha(true);
          this.exibirConfirmaSenha(true);

          setToken(token);
          this.SET_TOKEN_M(token);
          this.SET_AUTH_USER_M();
          resolve(data);
        })
        .catch((error: string) => {
          removeToken();

          reject(error);
        })
        .finally(() => this.STOP_LOADING_M());
    });
  }

  @Action
  async [AUTH_LOGOUT]() {
    return new Promise<void>((resolve) => {
      removeToken();

      this.SET_TOKEN_M('');
      this.SET_AUTH_USER_M();
      if (window.localStorage.getItem('IsLogged')?.includes('true')) {
        window.localStorage.setItem('IsLogged', 'false');
      }

      resolve();
    });
  }

  @Action
  async [GET_ID_BY_AUTH]() {
    return new Promise<void>((resolve) => {
      this.GET_AUTH_USER_M();
      resolve();
    });
  }
}

export default getModule(AuthModule);
