import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';

import Clinica, { IClinicaModel } from '../../models/Clinicas/ClinicaModel';
import PagedResult, { IPagedResult } from '../../models/Paginacao/PagedResult';
import ClinicaService from '../../services/ClinicaService';
import store from '..';
import {
  CHANGE_ADDRESS_VALUES,
  CHANGE_VALUES_CLINIC,
  CLEAR_CLINIC,
  CREATE_CLINIC,
  DELETE_CLINIC,
  DOWNLOAD_BACKUP,
  GERA_BACKUP,
  GET_ALL_CLINICS,
  GET_ALL_COMPANIES_FROM_CLINIC,
  GET_CLINIC,
  GET_CLINIC_CERTIFICATE,
  GET_CLINIC_TIMER,
  UPDATE_CLINIC,
  UPLOAD_CLINIC_CERTIFICADO_DIGITAL,
} from '../actions/ClinicaActions';

export interface IClinicState {
  clinica: IClinicaModel;
  clinicaPagedList: IPagedResult;
}

@Module({
  name: 'clinic',
  namespaced: true,
  dynamic: true,
  store,
})
class ClinicModule extends VuexModule implements IClinicState {
  private _clinica = new Clinica();

  private _clinicaPagedList = new PagedResult();

  private _loading = false;

  private _loadingClinicas = false;

  private _loadingBackup = false;

  private _loadingDownload = false;

  get clinica(): IClinicaModel {
    return this._clinica;
  }

  get clinicaPagedList(): IPagedResult {
    return this._clinicaPagedList;
  }

  get loading(): boolean {
    return this._loading;
  }

  get loadingClinicas(): boolean {
    return this._loadingClinicas;
  }

  get loadingBackup(): boolean {
    return this._loadingBackup;
  }

  get loadingDownload(): boolean {
    return this._loadingDownload;
  }

  @Mutation
  private SET_CLINIC_M(clinica: IClinicaModel) {
    this._clinica = clinica;
  }

  @Mutation
  private SET_TIMER_M(dataLimite: string) {
    this._clinica.testeDataLimite = dataLimite;
  }

  @Mutation
  private SET_CLINICS_M(clinicas: IPagedResult) {
    (this._clinicaPagedList as IPagedResult) = clinicas;
  }

  @Mutation
  private START_LOADING_M() {
    this._loading = true;
  }

  @Mutation
  private STOP_LOADING_M() {
    this._loading = false;
  }

  @Mutation
  private START_LOADING_CLINICS_M() {
    this._loadingClinicas = true;
  }

  @Mutation
  private STOP_LOADING_CLINICS_M() {
    this._loadingClinicas = false;
  }

  @Mutation
  private START_LOADING_BACKUP_M() {
    this._loadingBackup = true;
  }

  @Mutation
  private STOP_LOADING_BACKUP_M() {
    this._loadingBackup = false;
  }

  @Mutation
  private START_LOADING_DOWNLOAD_M() {
    this._loadingDownload = true;
  }

  @Mutation
  private STOP_LOADING_DOWNLOAD_M() {
    this._loadingDownload = false;
  }

  @Action
  public async [CHANGE_VALUES_CLINIC](payload: any) {
    const clinic = this.clinica;
    (clinic as any)[payload.field] = payload.value;
    this.SET_CLINIC_M(clinic);
  }

  @Action
  public async [CHANGE_ADDRESS_VALUES](payload: any) {
    const adress = this.clinica.endereco;

    (adress as any)[payload.field] = payload.value;

    if (payload.field === 'estado') {
      (adress as any).uf = payload.value;
    }

    this.SET_CLINIC_M({ ...this.clinica, endereco: adress });
  }

  @Action
  public async [CLEAR_CLINIC]() {
    this.SET_CLINIC_M(new Clinica());
    this.SET_CLINICS_M(new PagedResult());
  }

  @Action({ rawError: true })
  public async [GET_CLINIC](id: string) {
    return new Promise<IClinicaModel>((resolve, reject) => {
      this.START_LOADING_M();
      this.SET_CLINIC_M(new Clinica());
      ClinicaService.get(id)
        .then((data: IClinicaModel) => {
          this.SET_CLINIC_M(data);
          resolve(data);
        })
        .catch((error: string) => {
          reject(error);
        })
        .finally(() => this.STOP_LOADING_M());
    });
  }

  @Action({ rawError: true })
  public async [GET_CLINIC_TIMER](id: string) {
    return new Promise<string>((resolve, reject) => {
      this.START_LOADING_M();
      this.SET_CLINIC_M(new Clinica());

      ClinicaService.getClinicTimer(id)
        .then((data: string) => {
          this.SET_TIMER_M(data);
          resolve(data);
        })
        .catch((error: string) => {
          reject(error);
        })
        .finally(() => this.STOP_LOADING_M());
    });
  }

  @Action({ rawError: true })
  public async [GET_CLINIC_CERTIFICATE](id?: string) {
    return new Promise<IClinicaModel>((resolve, reject) => {
      this.START_LOADING_M();
      this.SET_CLINIC_M(new Clinica());
      ClinicaService.getCertificate(id)
        .then((data: IClinicaModel) => {
          this.SET_CLINIC_M(data);
          resolve(data);
        })
        .catch((error: string) => {
          reject(error);
        })
        .finally(() => this.STOP_LOADING_M());
    });
  }

  @Action({ rawError: true })
  public async [GET_ALL_CLINICS](payload: any = {}) {
    return new Promise<IPagedResult>((resolve, reject) => {
      this.START_LOADING_CLINICS_M();
      this.SET_CLINICS_M(new PagedResult());

      ClinicaService.getAll(payload)
        .then((data: IPagedResult) => {
          this.SET_CLINICS_M(data);
          resolve(data);
        })
        .catch((error: string) => {
          reject(error);
        })
        .finally(() => this.STOP_LOADING_CLINICS_M());
    });
  }

  @Action({ rawError: true })
  public async [GET_ALL_COMPANIES_FROM_CLINIC](payload: any = {}) {
    return new Promise<IPagedResult>((resolve, reject) => {
      ClinicaService.getCompanies()
        .then((data: any) => {
          resolve(data);
        })
        .catch((error: string) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public async [CREATE_CLINIC]() {
    return new Promise<string>((resolve, reject) => {
      this.START_LOADING_M();

      const postData = new FormData();

      Object.keys(this.clinica).forEach((key) => {
        if (typeof (this.clinica as any)[key] !== 'undefined' && (this.clinica as any)[key] !== null) {
          if (typeof (this.clinica as any)[key] === 'object' && key !== 'logomarca') {
            Object.keys((this.clinica as any)[key]).forEach((subKey) => {
              if ((Array.isArray(this.clinica[key]))) {
                Object.keys((this.clinica as any)[key][subKey]).forEach((subKey2) => {
                  if (typeof (this.clinica as any)[key][subKey][subKey2] !== 'undefined'
                  && (this.clinica as any)[key][subKey][subKey2] !== null) {
                    postData.append(`${key}[${subKey}].${subKey2}`, (this.clinica[key] as any)[subKey][subKey2]);
                  }
                });
              }
              if ((this.clinica as any)[key][subKey] !== null) {
                postData.append(`${key}.${subKey}`, (this.clinica as any)[key][subKey]);
              }
            });
          } else {
            postData.append(key, (this.clinica as any)[key]);
          }
        }
      });


      ClinicaService.create(postData as any)
        .then((data: any) => {
          resolve(data.mensagem);
        })
        .catch((error: any) => {
          reject(error);
        })
        .finally(() => this.STOP_LOADING_M());
    });
  }

  @Action({ rawError: true })
  public async [UPDATE_CLINIC](id: string) {
    return new Promise<string>((resolve, reject) => {
      this.START_LOADING_M();

      const postData = new FormData();

      Object.keys(this.clinica).forEach((key) => {
        if (typeof (this.clinica as any)[key] !== 'undefined' && (this.clinica as any)[key] !== null) {
          if (typeof (this.clinica as any)[key] === 'object' && key !== 'logomarca') {
            Object.keys((this.clinica as any)[key]).forEach((subKey) => {
              if ((Array.isArray(this.clinica[key]))) {
                Object.keys((this.clinica as any)[key][subKey]).forEach((subKey2) => {
                  if (typeof (this.clinica as any)[key][subKey][subKey2] !== 'undefined'
                  && (this.clinica as any)[key][subKey][subKey2] !== null) {
                    postData.append(`${key}[${subKey}].${subKey2}`, (this.clinica[key] as any)[subKey][subKey2]);
                  }
                });
              }
              if ((this.clinica as any)[key][subKey] !== null) {
                postData.append(`${key}.${subKey}`, (this.clinica as any)[key][subKey]);
              }
            });
          } else {
            postData.append(key, (this.clinica as any)[key]);
          }
        }
      });

      ClinicaService.update(id, (postData as any))
        .then((data: any) => {
          resolve(data.mensagem);
        })
        .catch((error: any) => {
          reject(error);
        })
        .finally(() => this.STOP_LOADING_M());
    });
  }

  @Action({ rawError: true })
  public async [UPLOAD_CLINIC_CERTIFICADO_DIGITAL](certificado: any) {
    return new Promise<string>((resolve, reject) => {
      this.START_LOADING_M();

      const postData = new FormData();

      Object.keys(certificado).forEach((key) => {
        if (typeof (certificado as any)[key] !== 'undefined' && (certificado as any)[key] !== null) {
          postData.append(key, certificado[key]);
        }
      });

      ClinicaService.uploadCertificadoDigital(postData as any)
        .then((data: any) => {
          resolve(data.mensagem);
        })
        .catch((error: any) => {
          reject(error);
        })
        .finally(() => this.STOP_LOADING_M());
    });
  }

  @Action({ rawError: true })
  public async [DELETE_CLINIC](id: string) {
    return new Promise<string>((resolve, reject) => {
      this.START_LOADING_M();

      ClinicaService.delete(id)
        .then((data: any) => {
          resolve(data.mensagem);
        })
        .catch((error: string) => {
          reject(error);
        })
        .finally(() => this.STOP_LOADING_M());
    });
  }

  @Action({ rawError: true })
  public async [GERA_BACKUP](clinicaId: string) {
    return new Promise<string>((resolve, reject) => {
      this.START_LOADING_BACKUP_M();

      ClinicaService.geraBackup(clinicaId)
        .then((data: any) => {
          resolve(data);
        })
        .catch((error: string) => {
          reject(error);
        })
        .finally(() => this.STOP_LOADING_BACKUP_M());
    });
  }

  @Action({ rawError: true })
  public async [DOWNLOAD_BACKUP](clinicaId: any) {
    return new Promise<string>((resolve, reject) => {
      this.START_LOADING_DOWNLOAD_M();

      ClinicaService.downloadBackup(clinicaId)
        .then((data: any) => {
          resolve(data);
        })
        .catch((error: string) => {
          reject(error);
        })
        .finally(() => this.STOP_LOADING_DOWNLOAD_M());
    });
  }
}



export default getModule(ClinicModule);
