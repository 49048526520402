import Role from './enums/Role';

const UsuariosEmpregadores: Array<String> = [
  Role.EmpregadorAdmin,
  Role.EmpregadorRevisao,
  Role.EmpregadorOperador,
  Role.EmpregadorOperadorRevisao,
  Role.EmpregadorApenasGestaoESocial,
  Role.EmpregadorApenasGestaoESocialRevisao,
  Role.EmpregadorGestaoEnvioInterno,
  Role.EmpregadorGestaoEnvioInternoRevisao,
];

export default UsuariosEmpregadores;
