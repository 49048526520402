import Permissao from '../constants/enums/PermissoesPadrao';
import Role from '../constants/enums/Role';
import { DEFAULT } from '../constants/Layout';
import {
  ACEITE_CLINICAGESTAO,
  ACEITE_EMPREGADORGESTAO,
  AFASTAMENTO_FORM,
  AFASTAMENTOS,
  AGENDA,
  AMBIENCE_FORM,
  APARELHOS,
  ASO,
  ASO_FORM,
  AUDITORIA,
  CABECALHO_RODAPE,
  CAT,
  CAT_FORM,
  CBO_FORM,
  CERTIFICADO_DIGITAL_FORM,
  CLINIC,
  CLINIC_FORM,
  COMPANY,
  COMPANY_FORM,
  CONFIGURA_RELATORIO_DE_EMPRESA,
  CONFIGURA_RELATORIO_MOVIMENTACAO_EPI,
  CONFIGURACOES_GERAIS,
  CONFIGURARELATORIOASO,
  CONFIGURARELATORIOCONVOCACAOPERIODICA,
  CONFIGURARELATORIOFATURAMENTO,
  CONFIGURARELATORIOFUNCIONARIOSPOREMPRESA,
  CONFIRM_EMAIL,
  CONVENIO,
  DASHBOARD_VIEW,
  DEPARTMENT,
  DEPARTMENT_FORM,
  EMPLOYEE,
  EMPLOYEE_FORM,
  EQUIPAMENT,
  EQUIPAMENT_FORM,
  ESOCIAL_DASHBOARD,
  ESOCIAL_DUVIDAS,
  ESOCIAL_EVENT,
  ESOCIAL_MANAGEMENT,
  ESOCIAL_PANEL,
  EXAME_TOXICOLOGICO_FORM,
  EXAMES_TOXICOLOGICOS,
  FATURA,
  FICHA_CLINICA_FORM,
  FUNCTION,
  FUNCTION_FORM,
  IMPORTA_FUNCIONARIO as IMPORTACAO,
  IMPORTACAO_EVENT,
  IMPRESSO_FORM,
  LAUDO_INSALUBRIDADE,
  LAUDO_INSALUBRIDADE_FORM,
  LAUDO_PERICULOSIDADE,
  LAUDO_PERICULOSIDADE_FORM,
  LOGIN,
  LOTE_DASHBOARD,
  LTCAT,
  LTCAT_FORM,
  LTIP,
  LTIP_FORM,
  MOVIMENTACAO_EPI,
  MY_USER,
  MYCOMPANY_FORM,
  ORDEM_DE_SERVICO_FORM,
  ORDENS_DE_SERVICO,
  PANEL,
  PCMSO,
  PCMSO_FORM,
  PGR,
  PGR_FORM,
  PLANEJAMENTO_ANUAL,
  PROCEDIMENTOS,
  PROFESSIONAL_RECORD,
  PROFESSIONAL_RECORD_FORM,
  QUESTAO_17,
  QUESTAO_106,
  QUESTAO_130,
  QUESTAO_174,
  QUESTAO_180_303_304,
  QUESTAO_203,
  QUESTAO_272,
  QUESTAO_381,
  QUESTAO_402,
  QUESTAO_411,
  QUESTAO_459,
  QUESTAO_543,
  QUESTAO_607,
  QUESTAO_609,
  QUESTAO_1170,
  QUESTAO_1172,
  QUESTAO_1323,
  QUESTAO_1327,
  QUESTAO_1557,
  QUESTAO_1748,
  QUESTAO_AO_CRIAR_LOTE,
  QUESTAO_ATUALIZACAO,
  QUESTAO_CONECTOR,
  QUESTAO_CONEXAO_CNPJ,
  QUESTAO_EVENTO_NAO_ENCONTRADO,
  RECOVERY_PASSWORD_FORM,
  RELATORIO_FUNCIONARIO_LOTE,
  RISCO_AGENTE_NOCIVO_FORM,
  ROUTER_VIEW,
  SERVICE_PROVIDER,
  SERVICE_PROVIDER_FORM,
  SERVICO_FATURAVEL,
  TREINAMENTOS,
  TREINAMENTOS_FORM,
  TREINAMENTOS_HISTORICO,
  USER,
  USER_FORM,
} from '../constants/views';

const routes = [
  // { path: '*', component: LOGIN }, // handle 404

  {
    path: '/login',
    name: 'login',
    component: LOGIN,
  },
  {
    path: '/aceite-clinica-gestao-interno',
    name: 'aceite-clinica-gestao-interno',
    component: ACEITE_CLINICAGESTAO,
  },

  {
    path: '/aceite-empregador-gestao-interno',
    name: 'aceite-empregador-gestao-interno',
    component: ACEITE_EMPREGADORGESTAO,
  },

  {
    path: '/recovery-password',
    name: 'recoveryPassword',
    component: RECOVERY_PASSWORD_FORM,
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: CONFIRM_EMAIL,
  },
  {
    path: '/',
    name: 'home',
    component: DEFAULT,
    meta: { auth: true },
    redirect: { name: 'dashboard' },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: DASHBOARD_VIEW,
      },
      {
        path: '/departments',
        name: 'departments',
        component: DEPARTMENT,
      },
      {
        path: '/configuracoes',
        name: 'configuracoes',
        component: CONFIGURACOES_GERAIS,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
            Role.ClinicaGestaoEnvioInternoRevisao,
            Role.ClinicaApenasGestaoESocial,
          ],
          permissions: [],
        },
      },
      {
        path: 'departments/:action/:id?',
        name: 'departmentsForm',
        component: DEPARTMENT_FORM,
        meta: { permissions: [Permissao.SetorRead] },
      },
      {
        path: 'functions',
        name: 'functions',
        component: FUNCTION,
        meta: { permissions: [Permissao.FuncaoReadAll] },
      },
      {
        path: 'cat',
        name: 'cat',
        component: CAT,
        meta: { permissions: [Permissao.CatReadAll] },
      },
      {
        path: 'cat/:action/:id?',
        name: 'catForm',
        component: CAT_FORM,
        meta: { permissions: [Permissao.CatReadAll] },
      },
      {
        path: '/ordens-de-servico',
        name: 'ordensDeServico',
        component: ORDENS_DE_SERVICO,
        meta: { permissions: [Permissao.OrdemDeServicoReadAll] },
      },
      {
        path: '/ordem-de-servico/:action/:id?',
        name: 'ordemDeServicoForm',
        component: ORDEM_DE_SERVICO_FORM,
        meta: { permissions: [Permissao.OrdemDeServicoRead] },
      },
      {
        path: 'pgr/:action/:id?',
        name: 'pgrForm',
        component: PGR_FORM,
        meta: { permissions: [Permissao.PGRRead] },
      },
      {
        path: 'pgr',
        name: 'pgr',
        component: PGR,
        meta: { permissions: [Permissao.PGRReadAll] },
      },
      {
        path: 'gestaoriscosagentes',
        name: 'gestaoriscosagentes',
        component: RISCO_AGENTE_NOCIVO_FORM,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaGestaoEnvioInternoRevisao,
          ],
          permissions: [
            Permissao.FatorDeRiscoCreate,
            Permissao.EquipamentosCreate,
            Permissao.PGRCreate,
          ],
        },
      },
      {
        path: 'aso',
        name: 'aso',
        component: ASO,
        meta: { permissions: [Permissao.AsoReadAll] },
      },
      {
        path: 'ficha-clinica',
        name: 'ficha-clinica',
        component: FICHA_CLINICA_FORM,
        meta: { permissions: [Permissao.FichaClinicaReadAll] },
      },
      {
        path: 'aso/:action/:id?',
        name: 'asoForm',
        component: ASO_FORM,
        meta: { permissions: [Permissao.AsoReadAll] },
      },
      {
        path: 'afastamento',
        name: 'afastamento',
        component: AFASTAMENTOS,
        meta: { permissions: [Permissao.AfastamentoReadAll] },
      },
      {
        path: 'afastamento/:action/:id?',
        name: 'afastamentoForm',
        component: AFASTAMENTO_FORM,
        meta: { permissions: [Permissao.AfastamentoRead] },
      },
      {
        path: 'exames-toxicologicos',
        name: 'exames-toxicologicos',
        component: EXAMES_TOXICOLOGICOS,
        meta: {
          roles: [
            Role.ClinicaAdmin,
            Role.ClinicaAdminRevisao,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaGestaoEnvioInternoRevisao,
          ],
          permissions: [Permissao.ExamesToxicologicosReadAll],
        },
      },
      {
        path: '/exame-toxicologico/:action/:id?',
        name: 'exame-toxicologico-form',
        component: EXAME_TOXICOLOGICO_FORM,
        meta: {
          roles: [
            Role.ClinicaAdmin,
            Role.ClinicaAdminRevisao,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaGestaoEnvioInternoRevisao,
          ],
          permissions: [Permissao.ExamesToxicologicosRead],
        },
      },
      {
        path: 'treinamentos',
        name: 'treinamentos',
        component: TREINAMENTOS,
        meta: { permissions: [Permissao.TreinamentoReadAll] },
      },
      {
        path: 'pcmso',
        name: 'pcmso',
        component: PCMSO,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.ClinicaGestaoEnvioInterno,
            Role.EmpregadorGestaoEnvioInterno,
          ],
          permissions: [Permissao.PcmsoRead],
        },
      },
      {
        path: 'pcmso/:action/:id?',
        name: 'pcmsoForm',
        component: PCMSO_FORM,
        meta: { permissions: [Permissao.PcmsoRead] },
      },
      {
        path: 'treinamentos/:action/:treinamentoEmpresaId?',
        name: 'treinamentosForm',
        component: TREINAMENTOS_FORM,
        meta: { permissions: [Permissao.TreinamentoRead] },
      },
      {
        path: 'meus-treinamentos-historico/:employeeId?',
        name: 'meus-treinamentos-historico',
        component: TREINAMENTOS_HISTORICO,
        meta: { permissions: [Permissao.TreinamentoRead] },

      },
      {
        path: 'functions/:action/:id?/:empresaId?',
        name: 'functionsForm',
        component: FUNCTION_FORM,
        meta: { permissions: [Permissao.FuncaoRead] },
      },
      {
        path: '/configuracao-impresso',
        name: 'configuracao-impresso',
        component: CERTIFICADO_DIGITAL_FORM,
      },
      {
        path: '/cabecalho-rodape',
        name: 'cabecalho-rodape',
        component: CABECALHO_RODAPE,
      },
      {
        path: '/impressos-personalizados',
        name: 'impressos-personalizados',
        component: IMPRESSO_FORM,
        meta: { permissions: [Permissao.ImpressoReadAll] },
      },
      {
        path: '/relatorios',
        component: ROUTER_VIEW,
        children: [
          {
            path: 'relatorio-funcionarios',
            name: 'relatorio-funcionarios',
            component: CONFIGURARELATORIOFUNCIONARIOSPOREMPRESA,
            meta: {
              roles: [
                Role.Admin,
                Role.ClinicaAdmin,
                Role.ClinicaMedico,
                Role.ClinicaTecOperador,
                Role.ClinicaEnfermeiro,
                Role.ClinicaSecretario,
                Role.EmpregadorAdmin,
                Role.EmpregadorOperador,
                Role.ClinicaGestaoEnvioInterno,
                Role.ClinicaAdminRevisao,
                Role.ClinicaMedicoRevisao,
                Role.ClinicaTecOperadorRevisao,
                Role.ClinicaEnfermeiroRevisao,
                Role.ClinicaSecretarioRevisao,
                Role.ClinicaGestaoEnvioInternoRevisao,
              ],
              permissions: [],
            },
          },
          {
            path: 'relatorio-aso',
            name: 'relatorio-aso',
            component: CONFIGURARELATORIOASO,
            meta: {
              roles: [
                Role.Admin,
                Role.ClinicaAdmin,
                Role.ClinicaMedico,
                Role.ClinicaTecOperador,
                Role.ClinicaEnfermeiro,
                Role.ClinicaSecretario,
                Role.EmpregadorAdmin,
                Role.EmpregadorOperador,
                Role.ClinicaGestaoEnvioInterno,
                Role.ClinicaAdminRevisao,
                Role.ClinicaMedicoRevisao,
                Role.ClinicaTecOperadorRevisao,
                Role.ClinicaEnfermeiroRevisao,
                Role.ClinicaSecretarioRevisao,
                Role.ClinicaGestaoEnvioInternoRevisao,
              ],
              permissions: [],
            },
          },
          {
            path: 'relatorio-periodica',
            name: 'relatorio-periodica',
            component: CONFIGURARELATORIOCONVOCACAOPERIODICA,
            meta: {
              roles: [
                Role.Admin,
                Role.ClinicaAdmin,
                Role.ClinicaMedico,
                Role.ClinicaTecOperador,
                Role.ClinicaEnfermeiro,
                Role.ClinicaSecretario,
                Role.EmpregadorAdmin,
                Role.EmpregadorOperador,
                Role.ClinicaAdminRevisao,
                Role.ClinicaMedicoRevisao,
                Role.ClinicaTecOperadorRevisao,
                Role.ClinicaEnfermeiroRevisao,
                Role.ClinicaSecretarioRevisao,
                Role.ClinicaGestaoEnvioInternoRevisao,
              ],
              permissions: [],
            },
          },
          {
            path: 'relatorio-faturamento',
            name: 'relatorio-faturamento',
            component: CONFIGURARELATORIOFATURAMENTO,
            meta: {
              roles: [
                Role.Admin,
                Role.ClinicaAdmin,
                Role.ClinicaGestaoEnvioInterno,
                Role.ClinicaGestaoEnvioInternoRevisao,
              ],
              permissions: [],
            },
          },
          {
            path: 'relatorio-empresa',
            name: 'relatorio-empresa',
            component: CONFIGURA_RELATORIO_DE_EMPRESA,
            meta: {
              roles: [
                Role.Admin,
                Role.ClinicaAdmin,
                Role.ClinicaMedico,
                Role.ClinicaTecOperador,
                Role.ClinicaEnfermeiro,
                Role.ClinicaSecretario,
                Role.ClinicaGestaoEnvioInterno,
                Role.ClinicaAdminRevisao,
                Role.ClinicaMedicoRevisao,
                Role.ClinicaTecOperadorRevisao,
                Role.ClinicaEnfermeiroRevisao,
                Role.ClinicaSecretarioRevisao,
                Role.ClinicaGestaoEnvioInternoRevisao,
              ],
              permissions: [],
            },
          },
          {
            path: 'relatorio-movimentacao-epi',
            name: 'relatorio-movimentacao-epi',
            component: CONFIGURA_RELATORIO_MOVIMENTACAO_EPI,
            meta: {
              roles: [
                Role.Admin,
                Role.ClinicaAdmin,
                Role.ClinicaMedico,
                Role.ClinicaTecOperador,
                Role.ClinicaEnfermeiro,
                Role.ClinicaSecretario,
                Role.EmpregadorAdmin,
                Role.EmpregadorOperador,
                Role.ClinicaGestaoEnvioInterno,
                Role.ClinicaAdminRevisao,
                Role.ClinicaMedicoRevisao,
                Role.ClinicaTecOperadorRevisao,
                Role.ClinicaEnfermeiroRevisao,
                Role.ClinicaSecretarioRevisao,
                Role.ClinicaGestaoEnvioInternoRevisao,
              ],
              permissions: [],
            },
          },
        ],
      },
      {
        path: '/agenda/:visualizacao?/:dia?',
        name: 'agenda',
        component: AGENDA,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
            Role.EmpregadorRevisao,
            Role.EmpregadorOperadorRevisao,
          ],
          permissions: [],
        },
      },
      {
        path: '/clinics',
        name: 'clinics',
        component: CLINIC,
        meta: { permissions: ['TenantReadAll', 'ConfiguracoesGeraisCreate'] },
      },
      {
        path: '/clinics/:action/:id?',
        name: 'clinicForm',
        component: CLINIC_FORM,
        meta: {
          permissions: [Permissao.TenantRead, Permissao.TenantCreate, Permissao.TenantUpdate, Permissao.ConfiguracoesGeraisCreate],
        },
      },
      {
        path: '/companies',
        name: 'companies',
        component: COMPANY,
        meta: { permissions: [Permissao.EmpresaReadAll] },
      },
      {
        path: '/companies/:action/:id?',
        name: 'companiesForm',
        component: COMPANY_FORM,
        meta: { permissions: [Permissao.EmpresaRead] },
      },
      {
        path: '/companies/create/consulta-cnpj',
        name: 'companiesFormConsultaCNPJ',
        component: COMPANY_FORM,
        meta: { permissions: [Permissao.EmpresaRead] },
      },
      {
        path: 'ltcat',
        name: 'ltcat',
        component: LTCAT,
        meta: { permissions: [Permissao.LtcatReadAll] },
      },
      {
        path: 'ltcat/:action/:id?',
        name: 'ltcatForm',
        component: LTCAT_FORM,
        meta: { permissions: [Permissao.LtcatRead] },
      },
      {
        path: 'laudoInsalubridade',
        name: 'laudoInsalubridade',
        component: LAUDO_INSALUBRIDADE,
        meta: { permissions: [Permissao.LaudoInsalubridadeReadAll] },
      },
      {
        path: 'laudoInsalubridade/:action/:id?',
        name: 'laudoInsalubridadeForm',
        component: LAUDO_INSALUBRIDADE_FORM,
        meta: { permissions: [Permissao.LaudoInsalubridadeRead] },
      },
      {
        path: 'ltip',
        name: 'ltip',
        component: LTIP,
        meta: { permissions: [Permissao.LTIPReadAll] },
      },
      {
        path: 'ltip/:action/:id?',
        name: 'ltipForm',
        component: LTIP_FORM,
        meta: { permissions: [Permissao.LTIPRead] },
      },
      {
        path: 'laudoPericulosidade',
        name: 'laudoPericulosidade',
        component: LAUDO_PERICULOSIDADE,
        meta: { permissions: [Permissao.LaudoPericulosidadeReadAll] },
      },
      {
        path: 'laudoPericulosidade/:action/:id?',
        name: 'laudoPericulosidadeForm',
        component: LAUDO_PERICULOSIDADE_FORM,
        meta: { permissions: [Permissao.LaudoPericulosidadeRead] },
      },
      {
        path: '/company/:companyId',
        component: ROUTER_VIEW,
        children: [
          {
            path: 'department/:departmentId',
            component: ROUTER_VIEW,
            children: [
              {
                path: 'ambience/:id?',
                name: 'ambienceForm',
                component: AMBIENCE_FORM,
                meta: { permissions: [Permissao.AmbienteRead] },
              },
            ],
          },
          {
            path: 'planejamento-anual',
            name: 'planejamento-anual',
            component: PLANEJAMENTO_ANUAL,
            meta: { permissions: [Permissao.PGRRead] },
          },
        ],
      },
      {
        path: '/users',
        name: 'users',
        component: USER,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaGestaoEnvioInterno,
            Role.EmpregadorAdmin,
            Role.ClinicaAdminRevisao,
            Role.ClinicaGestaoEnvioInternoRevisao,
            Role.EmpregadorRevisao,
          ],
          permissions: [Permissao.UsuarioReadAll],
        },
      },
      {
        path: '/myuser',
        name: 'myuser',
        component: MY_USER,
        meta: {
          roles: [
            Role.Host,
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaSecretarioRevisao,
            Role.ClinicaGestaoEnvioInternoRevisao,
            Role.ClinicaApenasGestaoESocialRevisao,
            Role.EmpregadorRevisao,
            Role.EmpregadorOperadorRevisao,
            Role.EmpregadorGestaoEnvioInternoRevisao,
            Role.EmpregadorApenasGestaoESocialRevisao,
          ],
          permissions: [Permissao.UsuarioPerfil],
        },
      },
      {
        path: '/users/:action/:id?',
        name: 'userForm',
        component: USER_FORM,
        meta: {
          permissions: [
            Permissao.UsuarioRead,
          ],
        },
      },
      {
        path: '/employees',
        name: 'employees',
        component: EMPLOYEE,
        meta: { permissions: [Permissao.FuncionarioReadAll] },
      },
      {
        path: '/importacao/:tipo',
        name: 'Importacao',
        props: true,
        component: IMPORTACAO,
        meta: { permissions: [Permissao.ImportarPlanilha] },
      },
      {
        path: '/importacao-event',
        name: 'importacao-event',
        component: IMPORTACAO_EVENT,
        meta: { Permissions: [Permissao.ImportarPlanilha] },
      },
      {
        path: '/aparelhos',
        name: 'aparelhos',
        component: APARELHOS,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaSecretarioRevisao,
            Role.ClinicaGestaoEnvioInternoRevisao,
          ],
          permissions: [Permissao.AparelhoReadAll],
        },
      },
      {
        path: '/employees/:action/:id?',
        name: 'employeeForm',
        component: EMPLOYEE_FORM,
        meta: { permissions: [Permissao.FuncionarioRead] },
      },
      {
        path: '/employee/:employeeId',
        component: ROUTER_VIEW,
        children: [
          {
            path: 'professionalRecords',
            name: 'professionalRecords',
            component: PROFESSIONAL_RECORD,
            meta: { permissions: [Permissao.RegistroProfReadAll] },
          },
          {
            path: 'professionalRecords/:action/:id?',
            name: 'professionalRecordForm',
            component: PROFESSIONAL_RECORD_FORM,
            meta: { permissions: [Permissao.RegistroProfRead] },
          },
        ],
      },
      {
        path: '/equipaments',
        name: 'equipaments',
        component: EQUIPAMENT,
        meta: { permissions: [Permissao.EquipamentosReadAll] },
      },
      {
        path: '/equipaments/:action/:id?',
        name: 'equipamentForm',
        component: EQUIPAMENT_FORM,
        meta: { permissions: [Permissao.EquipamentosRead] },
      },
      {
        path: '/movimentacaoepi',
        name: 'movimentacaoepi',
        component: MOVIMENTACAO_EPI,
        meta: { permissions: [Permissao.EquipamentosReadAll] },
      },
      {
        path: '/serviceProviders',
        name: 'serviceProvider',
        component: SERVICE_PROVIDER,
        meta: { permissions: [Permissao.PrestadorServReadAll] },
      },
      {
        path: '/serviceProviders/:action/:id?',
        name: 'serviceProviderForm',
        component: SERVICE_PROVIDER_FORM,
        meta: { permissions: [Permissao.PrestadorServRead] },
      },
      {
        path: '/audits',
        name: 'audit',
        component: AUDITORIA,
        meta: { permissions: [Permissao.AuditoriaReadAll] },
      },
      {
        path: '/cbo',
        name: 'cbo',
        component: CBO_FORM,
        meta: { permissions: [Permissao.TenantRead] },
      },
      {
        path: '/cbo/create',
        name: 'cboCreate',
        component: CBO_FORM,
        meta: { permissions: [Permissao.TenantRead] },
      },
      {
        path: '/cbo/edit/:id?',
        name: 'cboUpdate',
        component: CBO_FORM,
        meta: { permissions: [Permissao.TenantRead] },
      },
      {
        path: '/panel',
        name: 'panel',
        component: PANEL,
        meta: { permissions: [] },
      },
      {
        path: '/esocial-events',
        name: 'esocial-events',
        component: ESOCIAL_EVENT,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
            Role.ClinicaGestaoEnvioInternoRevisao,
            Role.ClinicaApenasGestaoESocialRevisao,
            Role.EmpregadorRevisao,
            Role.EmpregadorOperadorRevisao,
            Role.EmpregadorGestaoEnvioInternoRevisao,
            Role.EmpregadorApenasGestaoESocialRevisao,
          ],
          permissions: [Permissao.ESocialReadAll],
        },
      },
      {
        path: '/relatorio-funcionario-por-lote',
        name: 'relatorio-funcionario-por-lote',
        component: RELATORIO_FUNCIONARIO_LOTE,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
            Role.ClinicaGestaoEnvioInternoRevisao,
            Role.EmpregadorAdmin,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorRevisao,
            Role.EmpregadorGestaoEnvioInternoRevisao,
          ],
          permissions: [Permissao.GeraRelatorioDeEventosPorFuncionario],
        },
      },
      {
        path: '/esocial-dashboard',
        name: 'esocial-dashboard',
        component: ESOCIAL_DASHBOARD,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorRevisao,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
            Role.ClinicaGestaoEnvioInternoRevisao,
            Role.EmpregadorGestaoEnvioInternoRevisao,
            Role.ClinicaApenasGestaoESocialRevisao,
            Role.EmpregadorOperadorRevisao,
            Role.EmpregadorApenasGestaoESocialRevisao,
          ],
          permissions: [Permissao.ESocialReadAll],
        },
      },
      {
        path: '/esocial-panel',
        name: 'esocial-panel',
        component: ESOCIAL_PANEL,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorRevisao,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
            Role.ClinicaGestaoEnvioInternoRevisao,
            Role.EmpregadorGestaoEnvioInternoRevisao,
            Role.ClinicaApenasGestaoESocialRevisao,
            Role.EmpregadorOperadorRevisao,
            Role.EmpregadorApenasGestaoESocialRevisao,
          ],
          permissions: [
            Permissao.ESocialReadAll,
            Permissao.ESocialRead,
          ],
        },
      },
      {
        path: '/lotes-dashboard',
        name: 'lotes-dashboard',
        component: LOTE_DASHBOARD,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
            Role.EmpregadorOperadorRevisao,
            Role.ClinicaGestaoEnvioInternoRevisao,
            Role.EmpregadorGestaoEnvioInternoRevisao,
            Role.ClinicaApenasGestaoESocialRevisao,
            Role.EmpregadorApenasGestaoESocialRevisao,
          ],
          permissions: [Permissao.ESocialReadAll],
        },
      },
      {
        path: '/esocial-duvidas',
        name: 'esocial-duvidas',
        component: ESOCIAL_DUVIDAS,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.EmpregadorGestaoEnvioInternoRevisao,
            Role.ClinicaApenasGestaoESocialRevisao,
            Role.EmpregadorOperadorRevisao,
            Role.EmpregadorApenasGestaoESocialRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: 'https://www.elfutec.com/amea/curso/10002469/2',
        name: 'ajuda',
        beforeEnter(to, from, next) {
          window.location.href = 'https://www.elfutec.com/amea/curso/10002469/2';
        },
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
        },
      },
      {
        path: '/questao-17',
        name: 'questao-17',
        component: QUESTAO_17,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-106',
        name: 'questao-106',
        component: QUESTAO_106,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-180-303-304',
        name: 'questao-180-303-304',
        component: QUESTAO_180_303_304,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-203',
        name: 'questao-203',
        component: QUESTAO_203,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-272',
        name: 'questao-272',
        component: QUESTAO_272,
        meta: {
          roles: [
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-381',
        name: 'questao-381',
        component: QUESTAO_381,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-402',
        name: 'questao-402',
        component: QUESTAO_402,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-411',
        name: 'questao-411',
        component: QUESTAO_411,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-459',
        name: 'questao-459',
        component: QUESTAO_459,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-607',
        name: 'questao-607',
        component: QUESTAO_607,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-130',
        name: 'questao-130',
        component: QUESTAO_130,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-174',
        name: 'questao-174',
        component: QUESTAO_174,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-543',
        name: 'questao-543',
        component: QUESTAO_543,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-609',
        name: 'questao-609',
        component: QUESTAO_609,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-1170',
        name: 'questao-1170',
        component: QUESTAO_1170,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-1172',
        name: 'questao-1172',
        component: QUESTAO_1172,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-1323',
        name: 'questao-1323',
        component: QUESTAO_1323,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-1327',
        name: 'questao-1327',
        component: QUESTAO_1327,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-1557',
        name: 'questao-1557',
        component: QUESTAO_1557,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-1748',
        name: 'questao-1748',
        component: QUESTAO_1748,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-atualizacao',
        name: 'questao-atualizacao',
        component: QUESTAO_ATUALIZACAO,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-conexao-cnpj',
        name: 'questao-conexao-cnpj',
        component: QUESTAO_CONEXAO_CNPJ,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-evento-nao-encontrado',
        name: 'questao-evento-nao-encontrado',
        component: QUESTAO_EVENTO_NAO_ENCONTRADO,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-erro-ao-criar-lote',
        name: 'questao-erro-ao-criar-lote',
        component: QUESTAO_AO_CRIAR_LOTE,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/questao-conector',
        name: 'questao-conector',
        component: QUESTAO_CONECTOR,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
          ],
          permissions: [Permissao.ESocialRead],
        },
      },
      {
        path: '/lotes/:action/',
        name: 'lotes',
        component: ESOCIAL_MANAGEMENT,
        meta: {
          roles: [
            Role.Admin,
            Role.ClinicaAdmin,
            Role.ClinicaMedico,
            Role.ClinicaTecOperador,
            Role.ClinicaEnfermeiro,
            Role.ClinicaSecretario,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaApenasGestaoESocial,
            Role.EmpregadorAdmin,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.ClinicaAdminRevisao,
            Role.ClinicaMedicoRevisao,
            Role.ClinicaTecOperadorRevisao,
            Role.ClinicaEnfermeiroRevisao,
            Role.ClinicaSecretarioRevisao,
            Role.ClinicaGestaoEnvioInternoRevisao,
            Role.ClinicaApenasGestaoESocialRevisao,
            Role.EmpregadorRevisao,
            Role.EmpregadorOperadorRevisao,
            Role.EmpregadorGestaoEnvioInternoRevisao,
            Role.EmpregadorApenasGestaoESocialRevisao,
          ],
        },
      },

      {
        path: '/my-company/',
        name: 'my-company',
        component: MYCOMPANY_FORM,
        meta: {
          roles: [
            Role.EmpregadorAdmin,
            Role.EmpregadorRevisao,
            Role.EmpregadorOperador,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorApenasGestaoESocial,
            Role.EmpregadorOperadorRevisao,
            Role.EmpregadorGestaoEnvioInternoRevisao,
            Role.EmpregadorApenasGestaoESocialRevisao,
          ],
          permissions: [Permissao.MinhaEmpresaRead],
        },
      },
      {
        path: '/my-company',
        component: ROUTER_VIEW,
        children: [
          {
            path: 'my-departments',
            name: 'my-departments',
            component: DEPARTMENT,
            meta: {
              roles: [
                Role.EmpregadorAdmin,
                Role.EmpregadorOperador,
                Role.EmpregadorGestaoEnvioInterno,
                Role.EmpregadorRevisao,
                Role.EmpregadorOperadorRevisao,
                Role.EmpregadorGestaoEnvioInternoRevisao,
              ],
              permissions: [Permissao.SetorReadAll],
            },
          },
          {
            path: 'my-functions',
            name: 'my-functions',
            component: FUNCTION,
            meta: { permissions: [Permissao.FuncaoReadAll] },
          },
          {
            path: 'my-departments/:action/:id?',
            name: 'my-departmentsForm',
            component: DEPARTMENT_FORM,
            meta: { permissions: [Permissao.SetorRead] },
          },
          {
            path: 'my-department/:mydepartmentId',
            component: ROUTER_VIEW,
            children: [
              {
                path: 'my-ambience/:id?',
                name: 'my-ambienceForm',
                component: AMBIENCE_FORM,
                meta: { permissions: [Permissao.AmbienteRead] },
              },
              {
                path: 'my-functions/:action/:id?',
                name: 'my-functionsForm',
                component: FUNCTION_FORM,
                meta: { permissions: [Permissao.FuncaoRead] },
              },
            ],
          },
          {
            path: 'meus-treinamentos/:action/:id?',
            name: 'meus-treinamentosForm',
            component: TREINAMENTOS_FORM,
            meta: { permissions: [Permissao.TreinamentoRead] },
          },
        ],
      },
      {
        path: 'faturas',
        name: 'faturas',
        component: FATURA,
        meta: {
          roles: [
            Role.ClinicaAdmin,
            Role.ClinicaAdminRevisao,
            Role.EmpregadorAdmin,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaGestaoEnvioInternoRevisao,
            Role.EmpregadorGestaoEnvioInterno,
            Role.EmpregadorGestaoEnvioInternoRevisao,
          ],
          permissions: [Permissao.FaturaReadAll],
        },
      },
      {
        path: 'convenios',
        name: 'convenios',
        component: CONVENIO,
        meta: {
          roles: [
            Role.ClinicaAdmin,
            Role.ClinicaAdminRevisao,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaGestaoEnvioInternoRevisao,
          ],
          permissions: [Permissao.ConvenioReadAll],
        },
      },
      {
        path: 'procedimentos',
        name: 'procedimentos',
        component: PROCEDIMENTOS,
        meta: {
          roles: [
            Role.ClinicaAdmin,
            Role.ClinicaAdminRevisao,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaGestaoEnvioInternoRevisao,
          ],
          permissions: [Permissao.ProcedimentoReadAll],
        },
      },
      {
        path: 'servicos',
        name: 'servicos',
        component: SERVICO_FATURAVEL,
        meta: {
          roles: [
            Role.ClinicaAdmin,
            Role.ClinicaAdminRevisao,
            Role.ClinicaGestaoEnvioInterno,
            Role.ClinicaGestaoEnvioInternoRevisao,
          ],
          permissions: [Permissao.ServicoFaturavelReadAll],
        },
      },
    ],
  },
];

export default routes;
