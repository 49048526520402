import Role from './enums/Role';

const UsuariosClinicaRevisao: Array<String> = [
  Role.ClinicaAdminRevisao,
  Role.ClinicaMedicoRevisao,
  Role.ClinicaTecOperadorRevisao,
  Role.ClinicaEnfermeiroRevisao,
  Role.ClinicaSecretarioRevisao,
  Role.ClinicaGestaoEnvioInternoRevisao,
  Role.ClinicaApenasGestaoESocialRevisao,
];

export default UsuariosClinicaRevisao;
