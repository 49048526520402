import { AxiosError, AxiosResponse } from 'axios';

import { IAuthModel } from '../models/Auth/AuthModel';
import Api from './_Api';

export default class AutenticacaoService {
  private static readonly _url = 'login';

  public static async authenticate(data: IAuthModel) {
    return new Promise<IAuthModel>((resolve, reject) => {
      Api.post<IAuthModel>(AutenticacaoService._url, data)
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          const err = error.response ? error.response.data : '';
          reject(err);
        });
    });
  }
}
