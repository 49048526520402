<template ref="templatee">
  <div ref="dive">
    <validation-provider
      :name="label ? label : ' '"
      :rules="rules"
      :disabled="disabled"
      v-slot="{ errors, valid, validated }"
    >
      <b-form-group
        class="form-group"
        :label="label ? `${label} ${required ? '*' : ''}` : null"
        style="width: 100%"
      >
        <div
          :class="{'picker': true, 'mr-2': showRemove }"
        >
          <b-textarea
            v-if="!!multiline"
            :name="name"
            v-model="text"
            :placeholder="placeholder"
            rows="3"
            max-rows="3"
            :state="(rules.length && validated) ? valid : null"
            class="picker-input"
            no-resize
            disabled
            trim
          />
          <b-form-input
            v-else
            :name="name"
            type="text"
            v-model="text"
            :placeholder="placeholder"
            :state="(rules.length && validated) ? valid : null"
            class="picker-input ocultarIconeDanger"
            disabled
            trim
          />
          <b-button
            v-if="!showRemove"
            class="picker-button"
            variant="success"
            :disabled="disabled"
            @click="abreModal"
          >
            <fa-icon icon="search" />
          </b-button>
          <b-button
            v-else
            class="picker-button-remove-enabled"
            variant="success"
            :disabled="disabled"
            @click="abreModal"
          >
            <fa-icon icon="search" />
          </b-button>
          <b-button
            class="picker-button"
            v-if="showRemove"
            variant="danger"
            :disabled="disableRemove"
            @click="$emit('onRemove')"
          >
            <fa-icon icon="eraser" />
          </b-button>
        </div>
        <p v-show="validated && !valid" class="error-container text-danger small">
          {{ errors[0] }}
        </p>
      </b-form-group>
    </validation-provider>

    <b-modal
      :id="`modal-picker-${name}`"
      :size="tamanhoDaModal"
      v-model="showModal"
      :title="title ? title : label"
      centered
      scrolling
      @hide="aoFecharPicker"
    >

      <soi-paged-table
        v-if="!!pagedTable"
        :ref="`table-picker-${name}`"
        :data="modalItems"
        :fields="modalFields"
        :selectable="true"
        :loading="loading"
        :hasSortIcon="hasSortIcon"
        :selectMultiple="multiselect"
        @sortData="$emit('sortData', $event)"
        @fetchData="$emit('fetchData', $event)"
      >
        <template v-slot:status="{ item }">
          <span v-if="item.status === 0" class="badge badge-secondary">Não Informado</span>
          <span v-else-if="item.status === 1" class="badge badge-success">Ativo</span>
          <span v-else-if="item.status === 2" class="badge badge-danger">Inativo</span>
          <span v-else class="badge badge-info">Revisão</span>
        </template>
        <template
          v-for="field in modalFields.filter((item) => item.isTemplate && item.key !== 'status')"
          v-slot:[field.key]="row">
          <div :key="field.key">
            <slot :name="field.key" :linha="row"/>
          </div>
        </template>
        <template v-slot:periodo="row">
          <span v-if="row.item.periodo === 1">Dia(s)</span>
          <span v-else-if="row.item.periodo === 2">Semana(S)</span>
          <span v-else-if="row.item.periodo === 3">Mês(es)</span>
          <span v-else>Ano(s)</span>
        </template>
      </soi-paged-table>
      <soi-table
        v-else
        :ref="`table-picker-${name}`"
        :fields="modalFields"
        :items="modalItems"
        :quantityPerPage="5"
        :selectable="true"
        :selectMultiple="multiselect"
      />

      <soi-radio
        v-if="temFiltro"
        name="filtro"
        :disabled="desabilitaFiltro"
        :label="descricaoDoFiltro"
        :value="filtro"
        :options="opcoesDoFiltro"
        @change="$emit('mudancaFiltro', $event)"
      />
      <div slot="modal-footer">
        <soi-button
          label="Cancelar"
          icon="times"
          variant="danger"
          class="mr-2"
          @click="cancelarSelecao"
        />
        <soi-button
          label="Selecionar"
          icon="check"
          variant="success"
          @click="select"
        />
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

import Notification from '../../helpers/NotificationHelper';
import IDictionary from '../../models/Interfaces/IDictionary';
import IStylizedDictionary from '../../models/Interfaces/IStylizedDictionary';
import ITableField from '../../models/Interfaces/ITableField';
import { IPagedResult } from '../../models/Paginacao/PagedResult';
import ComponenteBase from '../ComponenteBase.vue';
import PagedTable from '../PagedTable.vue';

@Component
export default class Picker extends ComponenteBase {
  @Prop({ type: String, default: '' })
  public label!: string;

  @Prop({ type: String, default: '' })
  public title!: string;

  @Prop({ type: String, default: '' })
  public name!: string;

  @Prop({ type: String, default: '' })
  public value!: string;

  @Prop({ type: Boolean, default: false })
  public hasSortIcon!: string;

  @Prop({ type: String, default: '' })
  public placeholder!: string;

  @Prop({ type: Boolean, default: false })
  public disabled!: string;

  @Prop({ type: Boolean, default: false })
  public multiline!: boolean;

  @Prop({ type: Array, default: () => [] })
  public validation!: string[];

  @Prop({ type: Boolean, default: false })
  public pagedTable!: boolean;

  @Prop({ type: Boolean, default: false })
  public showRemove!: boolean;

  @Prop({ type: Boolean, default: false })
  public disableRemove!: boolean;

  @Prop({ type: Boolean, default: false })
  public loading!: boolean;

  @Prop({ type: Array, default: () => {} })
  public modalFields!: Array<ITableField>;

  @Prop({ default: () => {} })
  public modalItems!: Array<any> | IPagedResult;

  @Prop({ type: Boolean, default: false })
  public temFiltro!: boolean;

  @Prop({ type: String, default: '' })
  public descricaoDoFiltro!: string;

  @Prop({ type: Array, default: () => [] })
  public opcoesDoFiltro!: Array<IDictionary | IStylizedDictionary>;

  @Prop({ type: Boolean, default: false })
  public multiselect!: boolean;

  @Prop({ type: Boolean, default: false })
  public clearOnClose!: boolean;

  @Prop({ type: Boolean, default: false })
  public permitirSelecaoVazia!: boolean;

  @Prop({ type: Boolean, default: false })
  public templateDoPicker!: boolean;

  @Prop({ type: Boolean, default: false })
  public usarModalPersonalizada!: boolean;

  @Prop({ type: Boolean, default: false })
  public desabilitaFiltro!: boolean;

  @Prop({ type: String, default: 'lg' })
  public tamanhoDaModal!: string;

  public filtro!: any;

  public text: string;

  public showModal: boolean;

  constructor() {
    super();
    this.text = this.value || '';
    this.showModal = false;
    this.filtro = -1;
  }

  get rules() {
    return this.validation.join('|');
  }

  get required() {
    return this.validation.find((rule) => rule === 'required');
  }

  @Watch('value')
  public changeValue() {
    Vue.nextTick(() => {
      this.text = this.value;
    });
  }

  public select() {
    const items = (this.$refs[`table-picker-${this.name}`] as any).selectedItems;
    if (items.length === 0 && !this.permitirSelecaoVazia) {
      Notification.error(`É necessário selecionar pelo menos um(a) ${this.label ? this.label : this.title}`);
    } else {
      const table = this.$refs[`table-picker-${this.name}`];

      if (this.clearOnClose) {
        (table as any).clearSelection();
      }
      this.showModal = false;
      this.$emit('change', items);
    }
  }

  private titulo(ev: string): string {
    return ev;
  }

  public cancelarSelecao() {
    this.showModal = false;
    this.$emit('cancelar');
  }

  public abreModal() {
    if (this.usarModalPersonalizada) {
      this.$emit('abrirModalPersonalizada');
    } else {
      this.showModal = true;
      this.$emit('abrirModal');
    }
  }

  public aoFecharPicker() {
    this.$emit('aoFecharPicker');
  }

  /**
 * Desmarca todas as linhas da tabela do Picker.
 */
  public limparSelecao() {
    (this.$refs[`table-picker-${this.name}`] as PagedTable).limparSelecao();
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
  margin-bottom: 25px;
}

.picker {
  position: relative;

  .picker-input {
    padding-right: 40px;
  }

  .picker-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }


  .picker-button-remove-enabled {
    position: absolute;
    top: 0;
    right: 41;
    height: 100%;
    left: calc(100% - 82px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .picker-button-remove {
    position: absolute;
    top: 0;
    right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.error-container {
  position: absolute;
  top: 100%;
}

.ocultarIconeDanger {
  background-image: none !important;
}

</style>
