<template>
  <div>
    <validation-provider
      :ref="`editor-${field}`"
      :vid="field"
      :name="field ? field : ' '"
      :rules="rules"
      :disabled="disabled"
      v-slot="{ errors, valid, validated }"
    >
    <b-form-group :label="label ? `${label} ${required ? '*' : ''}` : null">
      <div v-if="showImportExport" class="position-absolute mr-3" style="right: 15px; top: 0;">
        <soi-button
          class="ml-5 text-primary"
          :id="`popover-target-${name}-3`"
          :placeholder="`Importar`"
          icon="file-export"
          size="lg"
          variant="link"
          @click="$emit('import', { field: name })"
        />
        <soi-button
          v-if="showImportExport"
          class="text-success"
          variant="link"
          size="lg"
          :id="`popover-target-${name}-2`"
          :placeholder="`Salvar`"
          icon="file-import"
          @click="$emit('export', { field: name })"
        />
      </div>
      <div v-if="limiteCaracteres > 0" class="position-absolute mr-3" style="right: 5px; top: 0; color:red; font-size: 14px;"> {{ validacao }}</div>
      <vue-editor
        id="editor"
        :class="disabled ? 'editorDesabilitado' : ''"
        :value="value"
        :editorToolbar="opcoesDaToolbar"
        :placeholder="placeholder"
        :disabled="disabled"
        :editorOptions="configuracoesDoEditor"
        @focus="onEditorFocus"
        @blur="onEditorBlur"
        @selection-change="onSelectionChange"
        @text-change="onTextChange"
        @input="$emit('change', {field: field, value: $event }); $emit('input', $event)">
      </vue-editor>
      <div
        v-show="validated && !valid"
        class="error-container text-danger small"
      >
        <div v-for="error in errors" :key="error">{{ error }}</div>
      </div>
    </b-form-group>
    </validation-provider>
  </div>
</template>
<script lang="ts">
import ImageCompress from 'quill-image-compress';
import { extend } from 'vee-validate';
import { max, min, required } from 'vee-validate/dist/rules';
import {
  Component,
  Prop,
} from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';
import { Quill } from 'vue2-editor/dist/vue2-editor.core';

import ComponenteBase from './ComponenteBase.vue';

extend('required', {
  ...required,
  message: (fieldName) => `O campo ${fieldName} é obrigatório`,
});

extend('min', {
  ...min,
  params: ['length'],
  message: 'O texto deve ter no mínimo {length} caracteres',
});

extend('max', {
  ...max,
  params: ['length'],
  message: 'O texto excede o tamanho máximo permitido de {length} caracteres',
});

const bold = Quill.import('formats/bold');
bold.tagName = 'b';
Quill.register(bold, true);

const italic = Quill.import('formats/italic');
italic.tagName = 'i';
Quill.register(italic, true);

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px'];
Quill.register(Size, true);

Quill.register('modules/imageCompress', ImageCompress);

const opcoesDefault = [
  [{ size: ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px'] }],
  ['bold', 'underline', 'strike'],
  [{ list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ['image'],
];

@Component({
  components: {
    VueEditor,
  },
})
export default class Editor extends ComponenteBase {
  @Prop({ type: String, default: '' })
  public value!: string;

  @Prop({ type: String, default: '' })
  public placeholder!: string;

  @Prop({ type: String, default: 'conteudo' })
  public field!: string;

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean;

  @Prop({ type: Array, default: () => [] })
  public validation!: string[];

  @Prop({ type: Array, default: () => opcoesDefault })
  opcoesDaToolbar!: Array<Object>;

  @Prop({ type: Boolean, default: false })
  public showImportExport!: boolean;

  @Prop({ type: String, default: '' })
  public name!: string;

  @Prop({ type: String, default: '' })
  public label!: string;

  @Prop({ type: Number, default: 0 })
  public limiteCaracteres!: number;

  public contadorCaracteres: number = 0;

  public validacao: string = '';

  get rules() {
    return this.validation.join('|');
  }

  get required() {
    return this.validation.find((rule) => rule === 'required');
  }

  get configuracoesDoEditor() {
    return {
      image: {
        uploadOptions: {
          accept: 'image/jpeg,image/jpg,image/png,image/gif',
        },
      },
      modules: {
        imageCompress: {
          quality: 0.7, // Varia a qualidade da imagem em uma escala de 0-1
          maxWidth: 1000, // Limita largura da imagem inserida no componente
          maxHeight: 1000, // Limita altura da imagem inserida no componente
          imageType: 'image/jpeg', // default
          debug: false, // Exibe no console o quanto a imagem foi comprimida
          suppressErrorLogging: false, // default
          insertIntoEditor: undefined, // default
        },
      },
    };
  }

  public onTextChange(quill) {
    if (this.limiteCaracteres > 0) {
      this.contadorCaracteres = quill.length();
      this.validacao = `${this.contadorCaracteres > this.limiteCaracteres ? `Excedeu limite de ${this.limiteCaracteres} caracteres` : ''}`;
    }
  }

  public onEditorBlur(quill) {
    return true;
  }

  public onEditorFocus(quill) {
    return true;
  }

  public onSelectionChange(range) {
    return true;
  }

  public focusEditor() {
    (document.querySelector('div.ql-editor') as HTMLDivElement).focus();
  }

  public criaPdf(): any {
    return document.querySelector('div.ql-editor');
  }
}
</script>

<style lang="scss" scoped>

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value="10px"]::before
{
    content: '10';
    font-size: 10px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value="12px"]::before {
    content: '12';
    font-size: 12px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value="14px"]::before {
    content: '14';
    font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value="16px"]::before {
    content: '16';
    font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value="18px"]::before {
    content: '18';
    font-size: 18px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value="20px"]::before {
    content: '20';
    font-size: 20px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value="24px"]::before {
    content: '24';
    font-size: 24px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value="28px"]::before {
    content: '28';
    font-size: 28px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value="32px"]::before {
    content: '32';
    font-size: 32px !important;
}
.editorDesabilitado {
  background-color: #E9ECEF;
}
</style>
