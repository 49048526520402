<template>
  <b-form-group :label="label ? label : null" :class="classe">
    <b-form-checkbox
      :name="name"
      v-model="checked"
      :disabled="disabled"
      @change="$emit('change', { field: name, value: $event }); $emit('input', $event)"
      :switch="chave"
    >
    <soi-loader v-if="loading" small />
      <div class="container px-0">
        <div>{{ text }}</div>
        <div v-if="exibirPopover" class="ml-2">
          <span v-if="descricaoDoPopover !== ''">{{ descricaoDoPopover }}</span>
          <i :id="`popover_${name}`" class="h5 fas fa-question-circle" style="color: #2f2ff4;"></i>
          <b-popover :target="`popover_${name}`" triggers="hover" placement="top">
            <template v-if="tituloDoPopover !== ''" #title>{{ tituloDoPopover }}</template>
            <span v-if="textoDoPopover !== ''">{{ textoDoPopover }}</span>
          </b-popover>
        </div>
      </div>
    </b-form-checkbox>
  </b-form-group>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

import ComponenteBase from '../ComponenteBase.vue';

@Component
export default class Checkbox extends ComponenteBase {
  @Prop({ type: String, default: '' })
  public label!: string;

  @Prop({ type: String, default: '' })
  public text!: string;

  @Prop({ type: String, default: '' })
  public name!: string;

  @Prop({ type: Boolean, default: false })
  private value!: boolean;

  @Prop({ type: String, default: 'form-group' })
  public classe!: string;

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean | number;

  @Prop({ default: false })
  public loading!: boolean;

  @Prop({ type: Boolean, default: false })
  public chave!: boolean;

  @Prop({ type: String, default: '' })
  public descricaoDoPopover!: string;

  @Prop({ type: Boolean, default: false })
  public exibirPopover!: boolean;

  @Prop({ type: String, default: '' })
  public tituloDoPopover!: string;

  @Prop({ type: String, default: '' })
  public textoDoPopover!: string;

  public checked: boolean;

  constructor() {
    super();
    this.checked = this.value;
  }

  @Watch('value')
  private changeValue() {
    Vue.nextTick(() => {
      this.checked = this.value;
    });
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
  margin-bottom: 25px;
}

.container{
  display: flex;
}

</style>
