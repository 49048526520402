import { AxiosError, AxiosResponse } from 'axios';

import { IEmpresa } from '@/models/Empresas/EmpresaModel';

import { IClinicaModel } from '../models/Clinicas/ClinicaModel';
import { IPagedResult } from '../models/Paginacao/PagedResult';
import Api from './_Api';

export default class ClinicaService {
  private static readonly _url = 'tenants';

  private static readonly _urlCertificate = 'clinicas/certificado-digital'

  private static readonly _urlCompanies = 'clinicas/empresas'

  private static readonly _urlTimer = 'tenants/data-limite';

  private static readonly _urlUpload = 'clinicas/upload-certificado-digital-S3';

  private static readonly _urlApagarCertificado = 'clinicas/apagar-certificado-digital-s3';

  private static readonly _urlBackup = 'clinicas/gera-backup';

  private static readonly _urlDownloadBackup = 'clinicas/download-backup';

  public static async get(id: string) {
    return new Promise<IClinicaModel>((resolve, reject) => {
      Api.get(ClinicaService._url, id)
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          const err = error.response ? error.response.data : '';
          reject(err);
        });
    });
  }

  public static async getClinicTimer(id: string) {
    return new Promise<string>((resolve, reject) => {
      Api.get(ClinicaService._urlTimer, id)
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          const err = error.response ? error.response.data : '';
          reject(err);
        });
    });
  }

  public static async getCertificate(id?: string) {
    return new Promise<IClinicaModel>((resolve, reject) => {
      Api.get(ClinicaService._urlCertificate, id)
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          const err = error.response ? error.response.data : '';
          reject(err);
        });
    });
  }

  public static async getCompanies() {
    return Api.get(this._urlCompanies)
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => {
        throw error.response ? error.response.data : '';
      });
  }

  public static async getCompaniesFiltro(id: string) {
    const url = `${ClinicaService._urlCompanies}/${id}`;
    return Api.get(url)
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => {
        throw error.response ? error.response.data : '';
      });
  }

  public static async getAll(payload: any = {}) {
    return new Promise<IPagedResult>((resolve, reject) => {
      Api.get(ClinicaService._url, '', { params: payload })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          const err = error.response ? error.response.data : '';
          reject(err);
        });
    });
  }

  public static async create(data: any) {
    (data as FormData).append('timezoneOffsetMinutes', String(new Date().getTimezoneOffset()));
    return new Promise<string>((resolve, reject) => {
      Api.post<IClinicaModel>(ClinicaService._url, data)
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          const errorData: any = error.response ? error.response.data : '';
          const errorMsg = errorData.message ? errorData.message : errorData;
          const status = error.response ? error.response.status : 400;
          const obj = { status, errorMsg };
          reject(obj);
        });
    });
  }

  public static async update(id: string, data: IClinicaModel) {
    return new Promise<string>((resolve, reject) => {
      Api.put<IClinicaModel>(ClinicaService._url, id, data)
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          const errorMsg = error.response ? error.response.data : '';
          const status = error.response ? error.response.status : 400;
          const obj = { status, errorMsg };
          reject(obj);
        });
    });
  }

  public static async uploadCertificadoDigital(data: IClinicaModel) {
    return new Promise<string>((resolve, reject) => {
      Api.put<IClinicaModel>(ClinicaService._urlUpload, '', data)
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          const err = error.response ? error.response.data : '';
          reject(err);
        });
    });
  }

  public static async apagarCertificadoDigital() {
    return new Promise<string>((resolve, reject) => {
      Api.delete(ClinicaService._urlApagarCertificado, '')
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          const err = error.response ? error.response.data : '';
          reject(err);
        });
    });
  }

  public static async delete(id: string) {
    return new Promise<string>((resolve, reject) => {
      Api.delete(ClinicaService._url, id)
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          const err = error.response ? error.response.data : '';
          reject(err);
        });
    });
  }

  public static async geraBackup(id: string) {
    return new Promise<string>((resolve, reject) => {
      const url = `${ClinicaService._urlBackup}/${id}`;
      Api.get(url)
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          const err = error.response ? error.response.data : '';
          reject(err);
        });
    });
  }

  public static async downloadBackup(nomeBackup: string) {
    return new Promise<string>((resolve, reject) => {
      const url = `${ClinicaService._urlDownloadBackup}/${nomeBackup}`;
      Api.get(url)
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          const err = error.response ? error.response.data : '';
          reject(err);
        });
    });
  }
}

