import Role from './enums/Role';

const UsuariosClinica: Array<String> = [
  Role.Admin,
  Role.ClinicaAdmin,
  Role.ClinicaMedico,
  Role.ClinicaTecOperador,
  Role.ClinicaEnfermeiro,
  Role.ClinicaSecretario,
  Role.ClinicaGestaoEnvioInterno,
  Role.ClinicaApenasGestaoESocial,
];

export default UsuariosClinica;
