export default class JwtHelper {
  private static urlBase64Decode(text: string) {
    let decoded = text.replace(/-/g, '+').replace(/_/g, '/');

    switch (decoded.length % 4) {
      case 0:
        break;

      case 2:
        decoded += '==';
        break;

      case 3:
        decoded += '=';
        break;

      default:
        throw new Error('Invalid base64 url text!');
    }

    return decodeURIComponent((window as any).escape(window.atob(decoded)));
  }

  /**
 * Decodifica um token JWT e retorna suas informações.
 *
 * @param {string} token - O token JWT a ser decodificado.
 * @returns {any} Um objeto contendo as informações decodificadas do token.
 * @throws {Error} Se o token for inválido ou não puder ser decodificado.
 */
  public static decodeToken(token: string) {
    if (!token) return { upn: '' };

    const parts = token.split('.');

    if (parts.length !== 3) throw new Error('Invalid token!');

    const decoded = JwtHelper.urlBase64Decode(parts[1]);

    if (!decoded) throw new Error('Can not decode the token!');

    return JSON.parse(decoded);
  }
}
