<template>
  <b-button
    class="btn-footer"
    :variant="variant"
    :title="title"
    v-b-tooltip
    :size="size"
    @click="$emit('click')"
  >

  <b-spinner :variant="colorLoading" data-testid="button-loading" v-if="loading" small />

    <fa-icon data-testid="button-icon" v-if="!!icon" :icon="!loading ? icon : ''" />
  </b-button>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';

import ComponenteBase from '../ComponenteBase.vue';

@Component
export default class IconButton extends ComponenteBase {
  @Prop({ type: String, default: '' })
  private title!: string;

  @Prop({ type: String, default: '' })
  private icon!: string;

  @Prop({ type: String, default: '' })
  private variant!: string;

  @Prop({ type: String, default: 'md' })
  private size!: string;

  @Prop({ default: false })
  private loading!: boolean;

  @Prop({ type: String, default: '' })
  private colorLoading!: string;
}
</script>
