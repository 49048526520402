

export class HoraHelper {
  /**
     * Converte texto no formato 'hh:mm' para quantidade de minutos
     * Ex:  01:00 -> retorna 60
     *      10:00 -> retorna 600
     *      32:00 -> retorna 1920
     *
     * @param hora texto no formato 'hh:mm' para converter em minutos
     * @returns quantidade de minutos a de 0 horas até hora
     */
  public static stringHoraParaMinutos(hora: string) {
    if (!this.formatoValido(hora)) throw Error('Erro ao converter formato de tempo.');
    const [horas, minutos] = hora.split(':').map((o) => Number(o));
    return horas * 60 + minutos;
  }

  /**
     * Converte dois texto no formato 'hh:mm' para quantidade de minutos
     *  e calcula a diferença entre a hora de inicio e fim
     * Ex:  inicio 01:00 e fim 02:00 -> retorna 60
     *      inicio 10:00 e fim 12:00 -> retorna 120
     *      inicio 10:00 e fim 8:00 -> retorna -120
     * @param inicio hora de inicio
     * @param fim hora fim
     * @returns diferença em minuto entre as duas horas
     */
  public static diferencaStringHoras(inicio: string, fim: string) {
    if (!this.formatoValido(inicio) || !this.formatoValido(fim)) throw Error('Erro ao converter formato de tempo.');
    const inicioMinutos = this.stringHoraParaMinutos(inicio);
    const fimMinutos = this.stringHoraParaMinutos(fim);
    return fimMinutos - inicioMinutos;
  }

  /**
     * Converte numero de minutos para string no formato 'hh:mm'
     * Ex: 60 ->  01:00
     *    600 ->  10:00
     *    -60 -> -01:00
     * @param minutos minutos a ser convertido
     * @returns string
     */
  public static minutosParaHora(minutos: number) {
    if (!minutos || Number.isNaN(minutos) || !Number.isFinite(minutos)) throw Error('Erro ao converter formato de tempo.');
    const hora = Math.floor(minutos / 60);
    const minuto = minutos - (hora * 60);
    return `${String(hora).padStart(2, '0')}:${String(minuto).padStart(2, '0')}`;
  }

  /**
     *  testa se o texto informado esta no formado
     *  compativel 'hh:mm'
     *
     * @param hora  texto a ser testado
     * @returns
     */
  public static formatoValido(hora: string) {
    return /^\d\d:\d\d$/.test(hora);
  }
}
