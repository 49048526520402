import Role from './enums/Role';

const UsuariosClinicaCompleto: Array<String> = [
  Role.ClinicaAdmin,
  Role.ClinicaMedico,
  Role.ClinicaTecOperador,
  Role.ClinicaEnfermeiro,
  Role.ClinicaSecretario,
  Role.ClinicaAdminRevisao,
  Role.ClinicaMedicoRevisao,
  Role.ClinicaTecOperadorRevisao,
  Role.ClinicaEnfermeiroRevisao,
  Role.ClinicaSecretarioRevisao,
];

export default UsuariosClinicaCompleto;
