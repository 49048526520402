<template>
    <div v-if="carregando && !userIsHost" class="timer">
        <div>
            Tempo restante de avaliação:
        </div>
        <div>
            {{ this.showDays + 'D ' + this.showHours + 'H ' + this.showMinutes + 'M '
            + this.showSeconds + 'S' }}
        </div>
    </div>
</template>

<script lang="ts">
import Swal from 'sweetalert2';
import { Component, Prop } from 'vue-property-decorator';

import NavigationHelper from '../helpers/NavigationHelper';
import Notification from '../helpers/NotificationHelper';
import { AUTH_LOGOUT } from '../store/actions/AutenticacaoActions';
import { CLEAR_CLINIC, GET_CLINIC_TIMER } from '../store/actions/ClinicaActions';
import authModule from '../store/modules/AutenticacaoModule';
import clinicModule from '../store/modules/ClinicaModule';
import ComponenteBase from './ComponenteBase.vue';

@Component
export default class TimeLine extends ComponenteBase {
    @Prop({ type: Array, default: () => [] })
    private data!: Array<any>;

    private limitDate: Date;

    private startDate?: Date;

    private carregando: boolean;

    private showSeconds: string;

    private showMinutes: string;

    private showHours: string;

    private showDays: string;

    private dataLimite: string;

    get seconds() {
      return 1000;
    }

    get minutes() {
      return this.seconds * 60;
    }

    get hours() {
      return this.minutes * 60;
    }

    get days() {
      return this.hours * 24;
    }

    get dataLimiteStore(): string {
      return clinicModule.clinica.testeDataLimite;
    }

    get userIsHost() {
      return authModule.user.tenantId === '';
    }

    constructor() {
      super();
      this.showSeconds = '';
      this.showMinutes = '';
      this.showHours = '';
      this.showDays = '';
      this.carregando = false;
      this.startDate = undefined;
      this.dataLimite = '';
      this.limitDate = new Date(this.dataLimite);
    }

    created() {
      this.fetchClinic();
    }

    private fetchClinic() {
      if (authModule.user.versaoTeste === '1') {
        clinicModule[GET_CLINIC_TIMER](authModule.user.tenantId.toString())
          .then((data) => {
            if (data !== '' && !this.userIsHost) {
              this.dataLimite = this.dataLimiteStore;
              this.tempoRestante();
            }
          })
          .catch((error) => {
            Notification.error(error);
          });
      }
    }

    destroyed() {
      clinicModule[CLEAR_CLINIC]();
    }

    private tempoRestante() {
      const timer = setInterval(() => {
        const timeZone = new Date().getTimezoneOffset() * 60 * 1000;
        const teste = new Date().getTime() + timeZone;
        const distance = new Date(this.dataLimite).getTime() - teste;

        if (distance < 0) {
          clearInterval(timer);
          this.showAlertMessage();
          this.carregando = true;
          this.logout();
          return;
        }
        this.showSeconds = this.formatNumber(Math.floor((distance % this.minutes) / this.seconds));
        this.showMinutes = this.formatNumber(Math.floor((distance % this.hours) / this.minutes));
        this.showHours = this.formatNumber(Math.floor((distance % this.days) / this.hours));
        this.showDays = this.formatNumber(Math.floor(distance / this.days));
        this.carregando = true;
      }, 1000);
    }

    private formatNumber(number: number): any {
      return number < 10 ? `0${number}` : number;
    }

    private showAlertMessage() {
      Swal.fire({
        title: 'Tempo Esgotado',
        text: 'Seu periodo de avaliação terminou.',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      }).then((result) => {
        this.$emit('timeExpired');
      });
    }

    private logout() {
      authModule[AUTH_LOGOUT]()
        .then(() => NavigationHelper.navigate('login'));
    }
}
</script>

<style lang="scss" scoped>
@import "../style/abstracts/_variables.scss";

.timer {
  font-size: 14px;
  color: $color-text-dark;
  margin: 0 10px;
  padding: 0px 10px 10px 0px;
  text-align: center;
}
</style>
