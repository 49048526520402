<template>
  <validation-provider
    :ref="`text-input-${name}`"
    :vid="name"
    :name="label ? label : ' '"
    :rules="rules"
    :disabled="disabled"
    v-slot="{ errors, valid, validated }"
  >
    <b-form-group :label="label && !showImportExport ? `${label} ${required ? '*' : ''}` : null">
      <template v-if="!!mask">
        <div :class="type ==='search' && 'box'">
          <b-form-input
            :name="name"
            :type="typeBtn"
            v-model="text"
            v-mask="mask"
            :maxlength="maxlength"
            :placeholder="placeholder"
            :disabled="disabled"
            :autocomplete="autoComplete"
            :state="(rules.length && validated && required) ? valid : null"
            @input="$emit('change', { value: $event, field: name });$emit('input', $event)"
            @blur="$emit('blur')"
            @keydown.space="bloqueiaEspacos($event)"
            trim
          />
          <b-button class="button" id="popover-target-1"
            variant="success"
            v-if="type === 'search'" @click="$emit('search')"
            :disabled="disabled"
            >
            <fa-icon icon="fa-magnifying-glass" size="sm"/>
          </b-button>
          <b-popover v-if="!!hintTextButton"
            target="popover-target-1" triggers="hover" placement="top">
            {{hintTextButton}}
          </b-popover>
          <b-form-invalid-feedback class="error-container">
            {{ errors[0] }}
          </b-form-invalid-feedback>
          <b-form-text v-show="!!hintText">{{ hintText }}</b-form-text>
        </div>
      </template>
      <template v-else>
        <div :class="(type.includes('search') && 'box') || showImportExport">
          <div>
            <span v-if="showImportExport" class="float-left mb-2">
              {{ label ? `${label} ${required ? '*' : ''}` : null }}
            </span>
            <div v-if="showImportExport" class="position-absolute" style="right: 0; top: 0">
              <soi-icon-button
                class="float-right text-success"
                :id="`popover-target-${name}-3`"
                icon="file-export"
                size="lg"
                @click="$emit('import', { field: name })"
                variant="link"
              />
              <soi-icon-button
                class="float-right text-primary"
                :id="`popover-target-${name}-2`"
                icon="file-import"
                size="lg"
                @click="$emit('export', { field: name })"
                variant="link"
              />
            </div>
          </div>
          <div>
            <b-input-group>
              <b-form-input
                :name="name"
                :type="!showPassword && showEye ? 'password' : 'text'"
                :showEye="showEye"
                :showEyeConfirmPassword="showEyeConfirmPassword"
                v-model="text"
                :maxlength="maxlength"
                :placeholder="placeholder"
                :disabled="disabled"
                :autocomplete="autoComplete"
                :state="(rules.length && validated && required) ? valid : null"
                @input="$emit('change', { value: $event, field: name });$emit('input', $event)"
                @blur="$emit('blur')"
                @keydown.space="bloqueiaEspacos($event)"
                trim
              />
              <div
                  v-show="validated && !valid"
                  class="error-container text-danger small mt-2"
              >
              <div v-for="error in errors" :key="error">{{ error }}</div>
            </div>
              <b-input-group-append>
                <b-button
                  v-if="enableEyeButton && !browserIsEdge"
                  class="eye position-absolute"
                  :id="`eye-${name}`"
                  style="right: 1.4rem; z-index: 3;"
                  @click="toggleShowPassword"
                  variant="link-secondary"
                >
                  <fa-icon :icon=" showPassword ? 'eye' : 'eye-slash'" size="sm"></fa-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <b-button class="button" id="popover-target-1"
              variant="success"
              v-if="type === 'search' && !showImportExport"
              @click="$emit('search')">
              <fa-icon icon="fa-magnifying-glass" size="sm"/>
          </b-button>
          <b-popover v-if="!!hintTextButton"
            target="popover-target-1" triggers="hover" placement="top">
            {{hintTextButton}}
          </b-popover>
          <b-popover v-if="!!hintTextButton2"
            :target="`popover-target-${name}-2`" triggers="hover" placement="top">
            {{hintTextButton2}}
          </b-popover>
          <b-popover v-if="!!hintTextButton3"
            :target="`popover-target-${name}-3`" triggers="hover" placement="top">
            {{hintTextButton3}}
          </b-popover>
          <b-form-invalid-feedback class="error-container">
            {{ errors[0] }}
          </b-form-invalid-feedback>
          <b-form-text v-show="!!hintText">{{ hintText }}</b-form-text>
        </div>
      </template>
    </b-form-group>
  </validation-provider>
</template>

<script lang="ts">
import { extend } from 'vee-validate';
import { max, min, required } from 'vee-validate/dist/rules';
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

import AutenticacaoModule from '../../store/modules/AutenticacaoModule';
import ComponenteBase from '../ComponenteBase.vue';

extend('required', {
  ...required,
  message: (fieldName) => `O campo ${fieldName} é obrigatório`,
});
extend('min', {
  ...min,
  params: ['length'],
  message: 'O texto deve ter no mínimo {length} caracteres',
});
extend('max', {
  ...max,
  params: ['length'],
  message: 'O texto excede o tamanho máximo permitido de {length} caracteres',
});

@Component
export default class TextInput extends ComponenteBase {
  @Prop({ type: String, default: '' })
  public label!: string;

  @Prop({ type: Boolean, default: false })
  public showEye!: boolean;

  @Prop({ type: Boolean, default: false })
  public showEyeConfirmPassword !: boolean;

  @Prop({ type: String, default: '' })
  public name!: string;

  @Prop({ type: [String, Number], default: '' })
  public value!: string | number;

  @Prop({ type: String, default: '' })
  public placeholder!: string;

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  private password!: boolean;

  @Prop({ type: String, default: '' })
  public mask!: string;

  @Prop({ type: Array, default: () => [] })
  public validation!: string[];

  @Prop({ type: String, default: '' })
  public hintText!: string;

  @Prop({ type: String, default: 'off' })
  public autoComplete!: String;

  @Prop({ type: Boolean, default: false })
  private desabilitaEspacos!: boolean;

  @Prop({ type: Number, default: 256 })
  public maxlength!: Number;

  @Prop({ type: String, default: 'text' })
  public type!: string;

  @Prop({ type: String, default: '' })
  public hintTextButton!: string;

  @Prop({ type: String, default: '' })
  public hintTextButton2!: string;

  @Prop({ type: String, default: '' })
  public hintTextButton3!: string;

  @Prop({ type: Boolean, default: false })
  public showImportExport!: boolean;

  public text: string | number;

  public userAgent: string;

  constructor() {
    super();
    this.userAgent = navigator.userAgent.toLowerCase();
    this.text = this.value || '';
  }

  @Watch('value')
  private changeValue() {
    Vue.nextTick(() => {
      this.text = this.value;
    });
  }

  @Watch('disabled')
  private onChangeDisabled() {
    (this.$refs[`text-input-${this.name}`] as any).reset();
  }

  get rules() {
    return this.validation.join('|');
  }

  get required() {
    return this.validation.find((rule) => rule === 'required');
  }

  get browserIsEdge() {
    return this.userAgent.indexOf('edge') !== -1;
  }

  get typeBtn() {
    return this.password ? 'password' : this.type;
  }

  get showPassword() {
    if (this.showEyeConfirmPassword) {
      return AutenticacaoModule._mostrarConfirmaSenha;
    }
    return AutenticacaoModule._mostrarSenha;
  }

  public toggleShowPassword() {
    if (this.showEyeConfirmPassword) {
      AutenticacaoModule.exibirConfirmaSenha();
    } else {
      AutenticacaoModule.exibirSenha();
    }
    this.password = !this.password;
  }

  get enableEyeButton() {
    return this.showEye && (this.value !== '' && this.value !== null);
  }

  public bloqueiaEspacos(event: any) {
    return this.desabilitaEspacos ? event.preventDefault() : () => {};
  }
}
</script>

<style lang="scss" scoped>
input {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.form-group {
  position: relative;
  margin-bottom: 25px;
}

.error-container {
  position: absolute;
  top: 90%;
}

.box {
  display: flex;
  border-radius: 0.25rem;
}

.box .form-control {
  border-right:0;
  border-top-right-radius:0;
  border-bottom-right-radius:0;
}

.box .button {
  border-radius: 0.09px;
  border-top-right-radius:0.25rem;
  border-bottom-right-radius:0.25rem;
  border-left: 0px;
}
.button-remove {
  border-radius: 0;
  border-top-left-radius:0rem;
  border-bottom-left-radius:0rem;
}
.square-border {
  border-radius: 0px;
}

.eye:focus {
  outline: none !important;
  border: none;
  box-shadow: none !important;
}
</style>
