<template>
  <vue-loading type="bars" color="#10a860" :size="size"></vue-loading>
</template>

<script lang="ts">
import { VueLoading } from 'vue-loading-template';
import { Component } from 'vue-property-decorator';

import ComponenteBase from './ComponenteBase.vue';

@Component({ components: { VueLoading } })
export default class SmallLoading extends ComponenteBase {
  get size() {
    return { width: '60px', height: '40px' };
  }
}
</script>

<style lang="scss" scoped>
.loading__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
