import Swal from 'sweetalert2';
import Vue from 'vue';
import VueRouter from 'vue-router';

import FuncionalidadesFaturamento from '../constants/listas/FuncionalidadesFaturamento';
import store from '../store';
import authModule from '../store/modules/AutenticacaoModule';
import ClinicaModule from '../store/modules/ClinicaModule';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  await store.replaceState;

  const { authenticated } = authModule;
  const { auth, roles, permissions } = (to.meta as any);

  if (!authenticated) {
    window.localStorage.setItem('IsLogged', 'false');
  }

  if ((!!auth || !!permissions || !!roles) && !authenticated) {
    return next({
      name: 'login',
      query: { redirect: to.fullPath },
    });
  }

  if (permissions) {
    const authorized = permissions.every((item: string) => {
      const index = authModule.user.permissions.indexOf(item);
      return index >= 0;
    });

    if (!authorized) {
      return next('/');
    }
  }

  if (roles) {
    const authorized = roles.some((item: string) => authModule.user.role === item);

    if (!authorized) {
      return next('/');
    }
  }
  return next();
});

router.beforeResolve(async (to, from, next) => {
  if (FuncionalidadesFaturamento.includes(to.name as string)
    && !ClinicaModule.clinica.temFaturamento) {
    next('/');
  }

  const alteracoesValores = store.state.changeValues;
  const notLogout = store.state.isNotLogout;
  if (alteracoesValores && notLogout) {
    const result = await Swal.fire({
      title: 'Deseja continuar?',
      text: 'As alterações não salvas serão perdidas.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sair Sem Salvar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      store.state.changeValues = false;
      next();
    } else {
      return;
    }
  }

  store.state.changeValues = false;
  next();
});

export default router;
