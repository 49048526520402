enum Role {
    Host = 'Host',
    Admin = 'Admin',
    ClinicaAdmin = 'ClinicaAdmin',
    ClinicaMedico = 'ClinicaMedico',
    ClinicaTecOperador = 'ClinicaTecOperador',
    ClinicaEnfermeiro = 'ClinicaEnfermeiro',
    ClinicaSecretario = 'ClinicaSecretario',
    ClinicaGestaoEnvioInterno = 'ClinicaGestaoEnvioInterno',
    ClinicaApenasGestaoESocial = 'ClinicaApenasGestaoESocial',
    EmpregadorAdmin = 'EmpregadorAdmin',
    EmpregadorOperador = 'EmpregadorOperador',
    EmpregadorGestaoEnvioInterno = 'EmpregadorGestaoEnvioInterno',
    EmpregadorApenasGestaoESocial = 'EmpregadorApenasGestaoESocial',
    ClinicaAdminRevisao = 'ClinicaAdminRevisao',
    ClinicaMedicoRevisao = 'ClinicaMedicoRevisao',
    ClinicaTecOperadorRevisao = 'ClinicaTecOperadorRevisao',
    ClinicaEnfermeiroRevisao = 'ClinicaEnfermeiroRevisao',
    ClinicaSecretarioRevisao = 'ClinicaSecretarioRevisao',
    ClinicaGestaoEnvioInternoRevisao = 'ClinicaGestaoEnvioInternoRevisao',
    ClinicaApenasGestaoESocialRevisao = 'ClinicaApenasGestaoESocialRevisao',
    EmpregadorRevisao = 'EmpregadorRevisao',
    EmpregadorOperadorRevisao = 'EmpregadorOperadorRevisao',
    EmpregadorGestaoEnvioInternoRevisao = 'EmpregadorGestaoEnvioInternoRevisao',
    EmpregadorApenasGestaoESocialRevisao = 'EmpregadorApenasGestaoESocialRevisao',
}

export default Role;
