export interface IPermission {
  code: string;
  name: string;
}

const permissions: IPermission[] = [
  { code: '0001', name: 'UsuarioReadAll' },
  { code: '0002', name: 'UsuarioRead' },
  { code: '0003', name: 'UsuarioCreate' },
  { code: '0004', name: 'UsuarioUpdate' },
  { code: '0005', name: 'UsuarioDelete' },
  { code: '0006', name: 'UsuarioPerfil' },
  { code: '0007', name: 'UsuarioUpdatePerfil' },

  { code: '0010', name: 'EmpresaReadAll' },
  { code: '0011', name: 'EmpresaRead' },
  { code: '0012', name: 'EmpresaCreate' },
  { code: '0013', name: 'EmpresaUpdate' },
  { code: '0014', name: 'EmpresaDelete' },
  { code: '0015', name: 'EmpresaDocumentoRead' },
  { code: '0016', name: 'EmpresaCertificadoUpdate' },

  { code: '0020', name: 'SetorReadAll' },
  { code: '0021', name: 'SetorRead' },
  { code: '0022', name: 'SetorCreate' },
  { code: '0023', name: 'SetorUpdate' },
  { code: '0024', name: 'SetorDelete' },

  { code: '0030', name: 'FuncaoReadAll' },
  { code: '0031', name: 'FuncaoRead' },
  { code: '0032', name: 'FuncaoCreate' },
  { code: '0033', name: 'FuncaoUpdate' },
  { code: '0034', name: 'FuncaoDelete' },

  { code: '0051', name: 'PcmsoRead' },
  { code: '0052', name: 'PcmsoCreate' },
  { code: '0053', name: 'PcmsoUpdate' },
  { code: '0054', name: 'PcmsoDelete' },

  { code: '0060', name: 'TreinamentoReadAll' },
  { code: '0061', name: 'TreinamentoRead' },
  { code: '0062', name: 'TreinamentoCreate' },
  { code: '0063', name: 'TreinamentoUpdate' },
  { code: '0064', name: 'TreinamentoDelete' },

  { code: '0070', name: 'FuncionarioReadAll' },
  { code: '0071', name: 'FuncionarioRead' },
  { code: '0072', name: 'FuncionarioCreate' },
  { code: '0073', name: 'FuncionarioUpdate' },
  { code: '0074', name: 'FuncionarioDelete' },

  { code: '0080', name: 'RegistroProfReadAll' },
  { code: '0081', name: 'RegistroProfRead' },
  { code: '0082', name: 'RegistroProfCreate' },
  { code: '0083', name: 'RegistroProfUpdate' },
  { code: '0084', name: 'RegistroProfDelete' },

  { code: '0090', name: 'CatReadAll' },
  { code: '0091', name: 'CatRead' },
  { code: '0092', name: 'CatCreate' },
  { code: '0093', name: 'CatUpdate' },
  { code: '0094', name: 'CatDelete' },

  { code: '0110', name: 'PrestadorServReadAll' },
  { code: '0111', name: 'PrestadorServRead' },
  { code: '0112', name: 'PrestadorServCreate' },
  { code: '0113', name: 'PrestadorServUpdate' },
  { code: '0114', name: 'PrestadorServDelete' },

  { code: '0120', name: 'EquipamentosReadAll' },
  { code: '0121', name: 'EquipamentosRead' },
  { code: '0122', name: 'EquipamentosCreate' },
  { code: '0123', name: 'EquipamentosUpdate' },
  { code: '0124', name: 'EquipamentosDelete' },

  { code: '0130', name: 'AmbienteRead' },
  { code: '0131', name: 'AmbienteUpdate' },

  { code: '0140', name: 'AsoReadAll' },
  { code: '0141', name: 'AsoRead' },
  { code: '0142', name: 'AsoCreate' },
  { code: '0143', name: 'AsoUpdate' },
  { code: '0144', name: 'AsoDelete' },

  { code: '0170', name: 'TenantReadAll' },
  { code: '0171', name: 'TenantRead' },
  { code: '0172', name: 'TenantCreate' },
  { code: '0173', name: 'TenantUpdate' },
  { code: '0174', name: 'TenantDelete' },
  { code: '0175', name: 'TenantTesteDataLimiteRead' },

  { code: '0180', name: 'RoleReadAll' },
  { code: '0181', name: 'RoleRead' },
  { code: '0182', name: 'RoleCreate' },
  { code: '0183', name: 'RoleUpdate' },
  { code: '0184', name: 'RoleDelete' },

  { code: '0190', name: 'UploadRead' },
  { code: '0191', name: 'UploadCreate' },
  { code: '0192', name: 'UploadUpdate' },
  { code: '0193', name: 'UploadDelete' },

  { code: '0200', name: 'EstadosMunicipiosIBGEReadAll' },
  { code: '0201', name: 'PaisReadAll' },
  { code: '0202', name: 'TipoLogradouroReadAll' },
  { code: '0203', name: 'CidReadAll' },
  { code: '0204', name: 'RiscoReadAll' },
  { code: '0205', name: 'ExameReadAll' },
  { code: '0206', name: 'CnaeReadAll' },
  { code: '0207', name: 'CodigoCategoriaReadAll' },

  { code: '0210', name: 'ClinicaRead' },
  { code: '0211', name: 'ClinicaUpdate' },
  { code: '0212', name: 'ClinicaCertificadoDigitalUpdate' },
  { code: '0213', name: 'ClinicaCertificadoDigitalRead' },

  { code: '0220', name: 'AuditoriaReadAll' },
  { code: '0221', name: 'AuditoriaRead' },

  { code: '0230', name: 'AgendaUpdate' },
  { code: '0231', name: 'AgendaCreate' },
  { code: '0232', name: 'AgendaRead' },
  { code: '0233', name: 'AgendaProcedimentos' },
  { code: '0234', name: 'AgendaConfigurarHorarioAntedimento' },
  { code: '0235', name: 'AgendaPacienteNaListaDeEsperas' },
  { code: '0236', name: 'AgendaPacienteNaListaDeEspera' },
  { code: '0237', name: 'AgendaImpressao' },

  { code: '0240', name: 'FatorDeRiscoReadAll' },
  { code: '0241', name: 'FatorDeRiscoRead' },
  { code: '0242', name: 'FatorDeRiscoCreate' },
  { code: '0243', name: 'FatorDeRiscoUpdate' },
  { code: '0244', name: 'FatorDeRiscoDelete' },

  { code: '0260', name: 'EpcEpiReadAll' },
  { code: '0261', name: 'EpcEpiRead' },
  { code: '0262', name: 'EpcEpiCreate' },
  { code: '0263', name: 'EpcEpiUpdate' },
  { code: '0264', name: 'EpcEpiDelete' },

  { code: '0270', name: 'DocumentosDeRiscosReadAll' },
  { code: '0271', name: 'DocumentosDeRiscosRead' },
  { code: '0272', name: 'DocumentosDeRiscosCreate' },
  { code: '0273', name: 'DocumentosDeRiscosUpdate' },
  { code: '0274', name: 'DocumentosDeRiscosDelete' },

  { code: '0281', name: 'ConfiguracaoDoExameRead' },
  { code: '0282', name: 'ConfiguracaoDoExameCreate' },
  { code: '0283', name: 'ConfiguracaoDoExameUpdate' },
  { code: '0284', name: 'ConfiguracaoDoExameDelete' },

  { code: '0290', name: 'ESocialReadAll' },
  { code: '0291', name: 'ESocialRead' },
  { code: '0293', name: 'ESocialReadConsulta' },
  { code: '0294', name: 'ESocialEnvio' },
  { code: '0295', name: 'ESocialDelete' },
  { code: '0296', name: 'ESocialReciboUpdate' },

  { code: '0300', name: 'MinhaEmpresaRead' },

  { code: '0310', name: 'AcessoExternoCreate' },

  { code: '0320', name: 'AtividadeInsalubreReadAll' },

  { code: '0330', name: 'CategoriasReadAll' },
  { code: '0331', name: 'CategoriasRead' },
  { code: '0332', name: 'CategoriasCreate' },
  { code: '0333', name: 'CategoriasUpdate' },
  { code: '0334', name: 'CategoriasDelete' },

  { code: '0340', name: 'FabricantesReadAll' },
  { code: '0341', name: 'FabricantesRead' },
  { code: '0342', name: 'FabricantesCreate' },
  { code: '0343', name: 'FabricantesUpdate' },
  { code: '0344', name: 'FabricantesDelete' },

  { code: '0350', name: 'EmailReenviaCreate' },
  { code: '0351', name: 'EmailEnvio' },

  { code: '0360', name: 'PeriodicidadeReadAll' },
  { code: '0361', name: 'PeriodicidadeRead' },
  { code: '0362', name: 'PeriodicidadeCreate' },
  { code: '0363', name: 'PeriodicidadeUpdate' },
  { code: '0364', name: 'PeriodicidadeDelete' },

  { code: '0370', name: 'TipoDeExameReadAll' },
  { code: '0371', name: 'TipoDeExameRead' },
  { code: '0372', name: 'TipoDeExameCreate' },

  { code: '0380', name: 'RegraExameOcupacionalReadAll' },
  { code: '0381', name: 'RegraExameOcupacionalRead' },
  { code: '0382', name: 'RegraExameOcupacionalCreate' },
  { code: '0383', name: 'RegraExameOcupacionalUpdate' },
  { code: '0384', name: 'RegraExameOcupacionalDelete' },

  { code: '0390', name: 'RegraExamePeriodicoReadAll' },
  { code: '0391', name: 'RegraExamePeriodicoRead' },
  { code: '0392', name: 'RegraExamePeriodicoCreate' },
  { code: '0393', name: 'RegraExamePeriodicoUpdate' },
  { code: '0394', name: 'RegraExamePeriodicoDelete' },

  { code: '0401', name: 'AgendamentoRead' },
  { code: '0402', name: 'AgendamentoCreate' },
  { code: '0403', name: 'AgendamentoUpdate' },
  { code: '0404', name: 'AgendamentoDelete' },

  { code: '0410', name: 'BloqueioReadAll' },
  { code: '0411', name: 'BloqueioRead' },
  { code: '0412', name: 'BloqueioCreate' },
  { code: '0413', name: 'BloqueioUpdate' },
  { code: '0414', name: 'BloqueioDelete' },

  { code: '0424', name: 'GeraRelatorioASO' },
  { code: '0425', name: 'GeraRelatorioFuncionariosPorEmpresa' },
  { code: '0426', name: 'GeraRelatorioConvocacaoPeriodica' },
  { code: '0427', name: 'GeraRelatorioDeEventosPorFuncionario' },

  { code: '0430', name: 'PGRReadAll' },
  { code: '0431', name: 'PGRRead' },
  { code: '0432', name: 'PGRCreate' },
  { code: '0434', name: 'PGRUpdate' },
  { code: '0435', name: 'PGRDelete' },

  { code: '0440', name: 'ImpressoCreate' },
  { code: '0441', name: 'ImpressoRead' },
  { code: '0442', name: 'ImpressoReadAll' },
  { code: '0443', name: 'ImpressoUpdate' },
  { code: '0444', name: 'ImpressoDelete' },

  { code: '0450', name: 'LtcatReadAll' },
  { code: '0451', name: 'LtcatRead' },
  { code: '0452', name: 'LtcatCreate' },
  { code: '0453', name: 'LtcatUpdate' },
  { code: '0454', name: 'LtcatDelete' },
  { code: '0455', name: 'LtcatImpresso' },

  { code: '0460', name: 'LoteS2210Create' },
  { code: '0461', name: 'LoteS2220Create' },
  { code: '0462', name: 'LoteS2240Create' },
  { code: '0463', name: 'LoteS3000Create' },
  { code: '0464', name: 'LoteS2230Create' },
  { code: '0465', name: 'LoteS2221Create' },

  { code: '0470', name: 'FichaClinicaReadAll' },
  { code: '0471', name: 'FichaClinicaRead' },
  { code: '0472', name: 'FichaClinicaCreate' },
  { code: '0473', name: 'FichaClinicaUpdate' },
  { code: '0474', name: 'FichaClinicaDelete' },

  { code: '0480', name: 'BibliografiaReadAll' },
  { code: '0481', name: 'BibliografiaRead' },
  { code: '0482', name: 'BibliografiaCreate' },
  { code: '0483', name: 'BibliografiaUpdate' },
  { code: '0484', name: 'BibliografiaDelete' },

  { code: '0490', name: 'AparelhoReadAll' },
  { code: '0491', name: 'AparelhoRead' },
  { code: '0492', name: 'AparelhoCreate' },
  { code: '0493', name: 'AparelhoUpdate' },
  { code: '0494', name: 'AparelhoDelete' },

  { code: '0500', name: 'ConvenioReadAll' },
  { code: '0501', name: 'ConvenioRead' },
  { code: '0502', name: 'ConvenioCreate' },
  { code: '0503', name: 'ConvenioUpdate' },
  { code: '0504', name: 'ConvenioDelete' },

  { code: '0510', name: 'ConfiguracaoFaturamentoReadAll' },
  { code: '0511', name: 'ConfiguracaoFaturamentoRead' },
  { code: '0512', name: 'ConfiguracaoFaturamentoCreate' },
  { code: '0513', name: 'ConfiguracaoFaturamentoUpdate' },
  { code: '0514', name: 'ConfiguracaoFaturamentoDelete' },

  { code: '0520', name: 'FaturaReadAll' },
  { code: '0521', name: 'FaturaRead' },
  { code: '0522', name: 'FaturaImpresso' },
  { code: '0523', name: 'FaturaCreate' },
  { code: '0524', name: 'FaturaUpdate' },
  { code: '0525', name: 'FaturaDelete' },
  { code: '0526', name: 'FaturaPorEmail' },

  { code: '0530', name: 'ProcedimentoReadAll' },
  { code: '0531', name: 'ProcedimentoRead' },
  { code: '0532', name: 'ProcedimentoCreate' },
  { code: '0533', name: 'ProcedimentoUpdate' },
  { code: '0534', name: 'ProcedimentoDelete' },

  { code: '0540', name: 'ServicoFaturavelReadAll' },
  { code: '0541', name: 'ServicoFaturavelRead' },
  { code: '0542', name: 'ServicoFaturavelCreate' },
  { code: '0543', name: 'ServicoFaturavelUpdate' },
  { code: '0544', name: 'ServicoFaturavelDelete' },

  { code: '0550', name: 'CboReadAll' },
  { code: '0551', name: 'CboCreate' },
  { code: '0552', name: 'CboUpdate' },
  { code: '0553', name: 'CboDelete' },

  { code: '0560', name: 'AfastamentoReadAll' },
  { code: '0561', name: 'AfastamentoRead' },
  { code: '0562', name: 'AfastamentoCreate' },
  { code: '0563', name: 'AfastamentoUpdate' },
  { code: '0564', name: 'AfastamentoDelete' },

  { code: '0570', name: 'MovimentacoesDeEPIReadAll' },
  { code: '0571', name: 'MovimentacoesDeEPIRead' },
  { code: '0572', name: 'MovimentacoesDeEPICreate' },

  { code: '0580', name: 'MotivoDeAfastamentoReadAll' },

  { code: '0590', name: 'ImportacaoPlanilha' },

  { code: '0600', name: 'AcompanhamentoReadAll' },
  { code: '0601', name: 'AcompanhamentoRead' },
  { code: '0602', name: 'AcompanhamentoCreate' },
  { code: '0603', name: 'AcompanhamentoUpdate' },
  { code: '0604', name: 'AcompanhamentoDelete' },

  { code: '0610', name: 'OrdemDeServicoReadAll' },
  { code: '0611', name: 'OrdemDeServicoRead' },
  { code: '0612', name: 'OrdemDeServicoCreate' },
  { code: '0613', name: 'OrdemDeServicoUpdate' },
  { code: '0614', name: 'OrdemDeServicoDelete' },

  { code: '0620', name: 'MarcadorPersonalizadoReadAll' },
  { code: '0621', name: 'MarcadorPersonalizadoRead' },
  { code: '0622', name: 'MarcadorPersonalizadoCreate' },
  { code: '0623', name: 'MarcadorPersonalizadoUpdate' },
  { code: '0624', name: 'MarcadorPersonalizadoDelete' },

  { code: '0630', name: 'RiscoPersonalizadoReadAll' },
  { code: '0631', name: 'RiscoPersonalizadoRead' },
  { code: '0632', name: 'RiscoPersonalizadoCreate' },
  { code: '0633', name: 'RiscoPersonalizadoUpdate' },
  { code: '0634', name: 'RiscoPersonalizadoDelete' },

  { code: '0640', name: 'LTIPReadAll' },
  { code: '0641', name: 'LTIPRead' },
  { code: '0642', name: 'LTIPCreate' },
  { code: '0643', name: 'LTIPUpdate' },
  { code: '0644', name: 'LTIPDelete' },
  { code: '0645', name: 'LTIPImpresso' },

  { code: '0650', name: 'LaudoPericulosidadeReadAll' },
  { code: '0651', name: 'LaudoPericulosidadeRead' },
  { code: '0652', name: 'LaudoPericulosidadeCreate' },
  { code: '0653', name: 'LaudoPericulosidadeUpdate' },
  { code: '0654', name: 'LaudoPericulosidadeDelete' },
  { code: '0655', name: 'LaudoPericulosidadeImpresso' },

  { code: '0660', name: 'LaudoInsalubridadeReadAll' },
  { code: '0661', name: 'LaudoInsalubridadeRead' },
  { code: '0662', name: 'LaudoInsalubridadeCreate' },
  { code: '0663', name: 'LaudoInsalubridadeUpdate' },
  { code: '0664', name: 'LaudoInsalubridadeDelete' },
  { code: '0665', name: 'LaudoInsalubridadeImpresso' },

  { code: '0670', name: 'ConfiguracoesGeraisReadAll' },
  { code: '0671', name: 'ConfiguracoesGeraisRead' },
  { code: '0672', name: 'ConfiguracoesGeraisCreate' },
  { code: '0673', name: 'ConfiguracoesGeraisUpdate' },

  { code: '0680', name: 'CamposPersonalizadosReadAll' },
  { code: '0681', name: 'CamposPersonalizadosRead' },
  { code: '0682', name: 'CamposPersonalizadosCreate' },
  { code: '0683', name: 'CamposPersonalizadosUpdate' },
  { code: '0684', name: 'CamposPersonalizadosDelete' },

  { code: '0690', name: 'ExamesToxicologicosReadAll' },
  { code: '0691', name: 'ExamesToxicologicosRead' },
  { code: '0692', name: 'ExamesToxicologicosCreate' },
  { code: '0693', name: 'ExamesToxicologicosUpdate' },
  { code: '0694', name: 'ExamesToxicologicosDelete' },
  { code: '0695', name: 'ExamesToxicologicosImpresso' },

  { code: '0700', name: 'ConfiguracaoCabecalhoRodapeRead' },
  { code: '0701', name: 'ConfiguracaoCabecalhoRodapeCreate' },
  { code: '0702', name: 'ConfiguracaoCabecalhoRodapeUpdate' },
];

export default permissions;
