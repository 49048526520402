<template>
  <section class="timeline__wrapper">
    <ul v-if="data.length" class="timeline">
      <template v-for="(item, index) in data">
        <div :key="item.key">
          <li
            :class="{
              timeline__item: true,
              'timeline__item--active': activeItem === item.key,
            }"
            @click="changeItem(item.key)"
          >
            <span :class="`timeline__icon timeline__icon--${item.type}`">
              <fa-icon v-if="item.icon" :icon="item.icon" />
            </span>
            <time class="timeline__time">{{ item.date }}</time>
            <time class="timeline__time">{{ item.status }}</time>
          </li>
          <span v-show="index < data.length - 1" class="timeline__separator" />
        </div>
      </template>
    </ul>

    <div v-if="data.length" class="timeline__details">
      <portal-target name="timeline-details-content" />
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';

import ComponenteBase from './ComponenteBase.vue';

@Component
export default class TimeLine extends ComponenteBase {
  @Prop({ type: Array, default: () => [] })
  private data!: Array<any>;

  private activeItem: number;

  constructor() {
    super();
    this.activeItem = 0;
  }

  private created() {
    if (this.data.length) {
      this.activeItem = this.data[0].key;
      this.$emit('showDetails', { data: this.data[0].details });
    }
  }

  private changeItem(key: number) {
    const index = this.data.findIndex((item) => item.key === key);
    this.activeItem = key;
    this.$emit('showDetails', { data: this.data[index].details });
  }
}
</script>

<style lang="scss" scoped>
.timeline__wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  max-height: inherit;
  overflow: hidden;
}

.timeline {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 240px;
  width: 100%;
  margin: 0 2px;
  padding: 1rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
}

.timeline__separator {
  display: block;
  width: 2px;
  height: 2rem;
  margin: 0.5rem auto;
  background-color: #efefef;
}

.timeline__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;

  &:hover:not(.timeline__item--active) {
    transform: scale(1.05);
  }

  .timeline__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #ffffff;
    background-color: #efefef;
    transition: all 0.2s;
  }

  .timeline__time {
    font-size: 1rem;
    transition: all 0.2s;
  }
}

.timeline__item--active {
  .timeline__icon {
    width: 45px;
    height: 45px;
    font-size: 1.5rem;
  }

  .timeline__time {
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.timeline__icon--success {
  background-color: #68cd86 !important;
}

.timeline__icon--error {
  background-color: #e54d42 !important;
}

.timeline__icon--warning {
  background-color: #ffb648 !important;
}

.timeline__icon--info {
  background-color: #44a4fc !important;
}

.timeline__details {
  flex: 1;
  max-height: 100%;
  padding: 1rem 1.5rem 0;
  border-radius: 0.125rem;
  border: solid 1px rgba($color: #000000, $alpha: 0.1);
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
}
</style>
