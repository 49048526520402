import IDictionary from '../models/Interfaces/IDictionary';

const Precisao: Array<IDictionary> = [
  { key: 0, value: '1' },
  { key: 1, value: '0.1' },
  { key: 2, value: '0.01' },
  { key: 3, value: '0.001' },
  { key: 4, value: '0.0001' },
];

export default Precisao;
