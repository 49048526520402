export default class MaskHelper {
  public static cnpj: string = '##.###.###/####-##';

  public static cpf: string = '###.###.###-##';

  public static cep: string = '#####-###';

  public static date: string = '##/##/####';

  public static phoneDDD: string = '##';

  public static phoneNumber: string = '###########'

  public static codCategoria: string = '###'

  public static nis: string = '###########'

  public static numeroMaximo: string = '#####################'

  public static numeroRecibo: string = '#.#.###################';

  public static hour: string = '##:##'

  public static numeroConselho: string = '##########';

  public static zapNumber: string = '55 (##)#####-####'

  public static codigoFamilia: string = '####'

  public static codigoOcupacao = '##'

  public static recibo = '#.#.###################'

  public static numeroDeProcesso17Digitos = '#####-####-###-##-##-#';

  public static numeroDeProcesso20Digitos = '#######.##.####.#.##.####';

  public static numeroDeProcesso21Digitos = '#######.##.####.#.##.#### #';

  public static codigoDoExameToxicologico = 'AA#########';
}
