import Role from './enums/Role';

const UsuariosRevisao: Array<String> = [
  Role.ClinicaAdminRevisao,
  Role.ClinicaMedicoRevisao,
  Role.ClinicaTecOperadorRevisao,
  Role.ClinicaEnfermeiroRevisao,
  Role.ClinicaSecretarioRevisao,
  Role.ClinicaGestaoEnvioInternoRevisao,
  Role.ClinicaApenasGestaoESocialRevisao,
  Role.EmpregadorRevisao,
  Role.EmpregadorOperadorRevisao,
  Role.EmpregadorGestaoEnvioInternoRevisao,
  Role.EmpregadorApenasGestaoESocialRevisao,
];

export default UsuariosRevisao;
