<template>
  <div class="select-picker-container">
    <div class="input-container">
      <b-form-input
        :placeholder="placeholder"
        v-model="text"
        debounce="500"
        autocomplete="off"
        trim
        :disabled="disabled"
        @update="filterItems"
        @focus="focused = true"
        @blur="onBlur"
      />
      <span class="icon">
        <fa-icon icon="search" />
      </span>
    </div>
    <ul v-show="focused && !!text.length" :class="suggestionsClass">
      <template v-if="!!suggestions.length">
        <li
          class="suggestion"
          v-for="item in suggestions"
          :key="item.key"
          @click="onSelect(item)"
        >
          <span v-html="formatItem(item.value)" />
        </li>
      </template>
      <li v-else class="suggestion">
        <span>Nenhum item encontrado...</span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';

import IDictionary from '../../models/Interfaces/IDictionary';
import { IPagedRequest } from '../../models/Paginacao/PagedRequest';
import ComponenteBase from '../ComponenteBase.vue';

@Component
export default class SelectPicker extends ComponenteBase {
  @Prop({ type: String, default: 'Procure por algum registro...' })
  private placeholder!: string;

  @Prop({ type: Array, default: () => [] })
  private items!: Array<IDictionary>;

  @Prop({ type: Array, default: () => [] })
  private excludeItems!: Array<any>;

  @Prop({ type: Array, default: () => [] })
  private excludeItemsPrestador!: Array<any>;

  @Prop({ type: Array, default: () => [] })
  private excludeItemsEquipaments!: Array<any>;

  @Prop({ type: Number, default: 5 })
  private quantity!: number;

  @Prop({ type: String, default: 'bottom' })
  private place!: string;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  private pagedOnApi!: boolean;

  @Prop({ type: Boolean, default: false })
  private resetOnSelect!: boolean;

  private text: string;

  private suggestions: Array<IDictionary>;

  private focused: boolean;

  get suggestionsClass() {
    return {
      suggestions: true,
      bottom: this.place === 'bottom',
      top: this.place === 'top',
    };
  }

  constructor() {
    super();
    this.text = '';
    this.suggestions = [];
    this.focused = false;
  }

  @Watch('disabled')
  private onDisabledChange() {
    this.suggestions = [];
    this.text = '';
  }

  @Watch('items')
  private onItemsChange() {
    if (this.pagedOnApi) {
      this.suggestions = this.items;
    }
  }

  @Watch('excludeItems')
  private onExcludeItemsChange() {
    if (this.text.length) {
      this.suggestions = this.items
        .filter((item) => {
          const value = item.value.toLowerCase();

          const match = value.includes(this.text.toLowerCase());
          const exclude = this.excludeItems.some((ex) => ex.id === item.key);

          return match && !exclude;
        })
        .slice(0, this.quantity);
    } else {
      this.suggestions = this.items;
    }
  }

  @Watch('excludeItemsPrestador')
  private onExcludeItemsChangePrestador() {
    if (this.text.length) {
      this.suggestions = this.items
        .filter((item) => {
          const value = item.value.toLowerCase();

          const match = value.includes(this.text.toLowerCase());
          const exclude = this.excludeItemsPrestador.some((ex) => ex.idPrestador === item.key);

          return match && !exclude;
        })
        .slice(0, this.quantity);
    } else {
      this.suggestions = this.items;
    }
  }

  @Watch('excludeItemsEquipaments')
  private onExcludeItemsChangeEquipaments() {
    if (this.text.length) {
      this.suggestions = this.items
        .filter((item) => {
          const value = item.value.toLowerCase();

          const match = value.includes(this.text.toLowerCase());
          const exclude = this.excludeItemsEquipaments.some((ex) => ex.equipamentoId === item.key);

          return match && !exclude;
        })
        .slice(0, this.quantity);
    } else {
      this.suggestions = this.items;
    }
  }

  private filterItems(search: string) {
    if (this.pagedOnApi) {
      const params: IPagedRequest = {
        page: 1,
        pageSize: this.quantity,
        termoDigitado: search,
      };

      this.$emit('fetchData', params);
    } else {
      this.suggestions = this.items
        .filter((item) => {
          const value = item.value.toLowerCase();

          const match = value.includes(this.text.toLowerCase());
          const exclude = this.excludeItems.some((ex) => ex.id === item.key);

          return match && !exclude;
        })
        .slice(0, this.quantity);
    }
  }

  private formatItem(text: string) {
    const index = text.toLowerCase().indexOf(this.text.toLowerCase());
    const str = text.slice(index, index + this.text.length);

    return text.replace(str, `<strong>${str}</strong>`);
  }

  private onBlur() {
    setTimeout(() => {
      this.focused = false;
    }, 200);
  }

  private onSelect(item: IDictionary) {
    this.$emit('select', item);

    const params: IPagedRequest = {
      page: 1,
      pageSize: this.quantity,
      termoDigitado: this.text,
    };

    if (this.resetOnSelect) {
      this.reset();
    }

    this.$emit('fetchData', params);
  }

  private reset() {
    this.text = '';

    const params: IPagedRequest = {
      page: 1,
      pageSize: this.quantity,
      termoDigitado: '',
    };

    this.$emit('fetchData', params);
  }
}
</script>

<style lang="scss" scoped>
.select-picker-container {
  position: relative;
  margin-bottom: 25px;
}

.input-container {
  position: relative;
  display: block;

  .form-control {
    &:focus {
      outline: 0;
      border-color: #ced4da;
      box-shadow: none;
    }
  }

  .icon {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.2);
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    background-color: transparent;
  }
}

.suggestions {
  position: absolute;
  left: 0;
  width: 100%;
  max-height: 200px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px rgba($color: #ced4da, $alpha: .2);
  background-color: #ffffff;
  overflow: auto;
  z-index: 1024;

  &.top {
    bottom: 50%;
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.bottom {
    top: 100%;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .suggestion {
    padding: 0.375rem 0.75rem;

    &:hover {
      background-color: #e9ecef;
      cursor: pointer;
    }

    & + .suggestion {
      border-top: 1px solid #ced4da;
    }
  }
}
</style>
