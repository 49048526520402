enum PermissoesPadrao {
  UsuarioReadAll = 'UsuarioReadAll',
  UsuarioRead = 'UsuarioRead',
  UsuarioCreate = 'UsuarioCreate',
  UsuarioUpdate = 'UsuarioUpdate',
  UsuarioDelete = 'UsuarioDelete',
  UsuarioPerfil = 'UsuarioPerfil',
  UsuarioUpdatePerfil = 'UsuarioUpdatePerfil',
  EmailReenviaCreate = 'EmailReenviaCreate',
  EmpresaReadAll = 'EmpresaReadAll',
  EmpresaRead = 'EmpresaRead',
  EmpresaDocumentoRead = 'EmpresaDocumentoRead',
  EmpresaCreate = 'EmpresaCreate',
  EmpresaUpdate = 'EmpresaUpdate',
  EmpresaDelete = 'EmpresaDelete',
  MinhaEmpresaRead = 'MinhaEmpresaRead',
  SetorReadAll = 'SetorReadAll',
  SetorRead = 'SetorRead',
  SetorCreate = 'SetorCreate',
  SetorUpdate = 'SetorUpdate',
  FuncaoReadAll = 'FuncaoReadAll',
  FuncaoRead = 'FuncaoRead',
  FuncaoCreate = 'FuncaoCreate',
  FuncaoUpdate = 'FuncaoUpdate',
  PcmsoRead = 'PcmsoRead',
  TreinamentoReadAll = 'TreinamentoReadAll',
  TreinamentoRead = 'TreinamentoRead',
  TreinamentoCreate = 'TreinamentoCreate',
  TreinamentoUpdate = 'TreinamentoUpdate',
  TreinamentoDelete = 'TreinamentoDelete',
  FuncionarioReadAll = 'FuncionarioReadAll',
  FuncionarioRead = 'FuncionarioRead',
  FuncionarioCreate = 'FuncionarioCreate',
  FuncionarioUpdate = 'FuncionarioUpdate',
  RegistroProfReadAll = 'RegistroProfReadAll',
  RegistroProfRead = 'RegistroProfRead',
  RegistroProfCreate = 'RegistroProfCreate',
  RegistroProfUpdate = 'RegistroProfUpdate',
  CatReadAll = 'CatReadAll',
  CatRead = 'CatRead',
  CatCreate = 'CatCreate',
  CatUpdate = 'CatUpdate',
  PrestadorServReadAll = 'PrestadorServReadAll',
  PrestadorServRead = 'PrestadorServRead',
  PrestadorServCreate = 'PrestadorServCreate',
  PrestadorServUpdate = 'PrestadorServUpdate',
  EquipamentosReadAll = 'EquipamentosReadAll',
  EquipamentosRead = 'EquipamentosRead',
  EquipamentosCreate = 'EquipamentosCreate',
  EquipamentosUpdate = 'EquipamentosUpdate',
  AmbienteRead = 'AmbienteRead',
  AmbienteUpdate = 'AmbienteUpdate',
  AsoReadAll = 'AsoReadAll',
  AsoRead = 'AsoRead',
  AsoCreate = 'AsoCreate',
  AsoUpdate = 'AsoUpdate',
  AsoDelete = 'AsoDelete',
  TenantReadAll = 'TenantReadAll',
  TenantRead = 'TenantRead',
  TenantCreate = 'TenantCreate',
  TenantUpdate = 'TenantUpdate',
  TenantDelete = 'TenantDelete',
  RoleReadAll = 'RoleReadAll',
  RoleRead = 'RoleRead',
  RoleCreate = 'RoleCreate',
  RoleUpdate = 'RoleUpdate',
  UploadRead = 'UploadRead',
  UploadCreate = 'UploadCreate',
  EstadosMunicipiosIBGEReadAll = 'EstadosMunicipiosIBGEReadAll',
  PaisReadAll = 'PaisReadAll',
  TipoLogradouroReadAll = 'TipoLogradouroReadAll',
  CidReadAll = 'CidReadAll',
  RiscoReadAll = 'RiscoReadAll',
  ExameReadAll = 'ExameReadAll',
  CnaeReadAll = 'CnaeReadAll',
  ClinicaRead = 'ClinicaRead',
  ClinicaUpdate = 'ClinicaUpdate',
  ClinicaUpdateCertificado = 'ClinicaUpdateCertificado',
  AuditoriaReadAll = 'AuditoriaReadAll',
  AuditoriaRead = 'AuditoriaRead',
  AgendaUpdate = 'AgendaUpdate',
  AgendaCreate = 'AgendaCreate',
  AgendaRead = 'AgendaRead',
  AgendaProcedimentos = 'AgendaProcedimentos',
  AgendaConfigurarHorarioAntedimento = 'AgendaConfigurarHorarioAntedimento',
  AgendaPacienteNaListaDeEsperas = 'AgendaPacienteNaListaDeEsperas',
  AgendaPacienteNaListaDeEspera = 'AgendaPacienteNaListaDeEspera',
  FatorDeRiscoReadAll = 'FatorDeRiscoReadAll',
  FatorDeRiscoRead = 'FatorDeRiscoRead',
  FatorDeRiscoUpdate = 'FatorDeRiscoUpdate',
  FabricantesUpdate = 'FabricantesUpdate',
  PeriodicidadeReadAll = '  PeriodicidadeReadAll',
  PeriodicidadeRead = 'PeriodicidadeRead',
  PeriodicidadeCreate = 'PeriodicidadeCreate',
  PeriodicidadeUpdate = 'PeriodicidadeUpdate',
  TipoDeExameReadAll = 'TipoDeExameReadAll',
  RegraExameOcupacionalReadAll = 'RegraExameOcupacionalReadAll',
  RegraExameOcupacionalRead = 'RegraExameOcupacionalRead',
  RegraExameOcupacionalCreate = 'RegraExameOcupacionalCreate',
  RegraExameOcupacionalUpdate = 'RegraExameOcupacionalUpdate',
  RegraExamePeriodicoReadAll = 'RegraExamePeriodicoReadAll',
  RegraExamePeriodicoRead = 'RegraExamePeriodicoRead',
  RegraExamePeriodicoCreate = 'RegraExamePeriodicoCreate',
  RegraExamePeriodicoUpdate = 'RegraExamePeriodicoUpdate',
  AgendamentoReadAll = 'AgendamentoReadAll',
  AgendamentoRead = 'AgendamentoRead',
  AgendamentoCreate = 'AgendamentoCreate',
  AgendamentoUpdate = 'AgendamentoUpdate',
  BloqueioReadAll = 'BloqueioReadAll',
  BloqueioRead = 'BloqueioRead',
  BloqueioCreate = 'BloqueioCreate',
  BloqueioUpdate = 'BloqueioUpdate',
  GeraRelatorioASO = 'GeraRelatorioASO',
  GeraRelatorioFuncionariosPorEmpresa = 'GeraRelatorioFuncionariosPorEmpresa',
  GeraRelatorioConvocacaoPeriodica = 'GeraRelatorioConvocacaoPeriodica',
  GeraRelatorioDeEventosPorFuncionario = 'GeraRelatorioDeEventosPorFuncionario',
  PGRReadAll = 'PGRReadAll',
  PGRRead = 'PGRRead',
  PGRCreate = 'PGRCreate',
  PGRUpdate = 'PGRUpdate',
  PGRDelete = 'PGRDelete',
  ImpressoCreate = 'ImpressoCreate',
  ImpressoRead = 'ImpressoRead',
  ImpressoReadAll = 'ImpressoReadAll',
  ImpressoUpdate = 'ImpressoUpdate',
  LtcatReadAll = 'LtcatReadAll',
  LtcatRead = 'LtcatRead',
  LtcatCreate = 'LtcatCreate',
  LtcatUpdate = 'LtcatUpdate',
  LtcatDelete = 'LtcatDelete',
  AparelhoReadAll = 'AparelhoReadAll',
  AparelhoRead = 'AparelhoRead',
  AparelhoCreate = 'AparelhoCreate',
  AparelhoUpdate = 'AparelhoUpdate',
  AparelhoDelete = 'AparelhoDelete',
  BibliografiaReadAll = 'BibliografiaReadAll',
  BibliografiaRead = 'BibliografiaRead',
  BibliografiaCreate = 'BibliografiaCreate',
  BibliografiaUpdate = 'BibliografiaUpdate',
  BibliografiaDelete = 'BibliografiaDelete',
  ConsultaCNPJRead = 'ConsultaCNPJRead',
  LoteS2210Create = 'LoteS2210Create',
  LoteS2220Create = 'LoteS2220Create',
  LoteS2221Create = 'LoteS2221Create',
  LoteS2230Create = 'LoteS2230Create',
  LoteS2240Create = 'LoteS2240Create',
  LoteS3000Create = 'LoteS3000Create',
  FaturaReadAll = 'FaturaReadAll',
  FaturaRead = 'FaturaRead',
  ConvenioReadAll = 'ConvenioReadAll',
  ConvenioRead = 'ConvenioRead',
  ConvenioCreate = 'ConvenioCreate',
  ConvenioUpdate = 'ConvenioUpdate',
  ConvenioDelete = 'ConvenioDelete',
  ProcedimentoReadAll = 'ProcedimentoReadAll',
  ProcedimentoRead = 'ProcedimentoRead',
  ProcedimentoCreate = 'ProcedimentoCreate',
  ProcedimentoUpdate = 'ProcedimentoUpdate',
  ProcedimentoDelete = 'ProcedimentoDelete',
  PcmsoCreate = 'PcmsoCreate',
  PcmsoUpdate = 'PcmsoUpdate',
  PcmsoDelete = 'PcmsoDelete',
  EquipamentosDelete = 'EquipamentosDelete',
  FichaClinicaReadAll = 'FichaClinicaReadAll',
  FichaClinicaRead = 'FichaClinicaRead',
  FichaClinicaCreate = 'FichaClinicaCreate',
  FichaClinicaUpdate = 'FichaClinicaUpdate',
  FichaClinicaDelete = 'FichaClinicaDelete',
  ClinicaCertificadoDigitalRead = 'ClinicaCertificadoDigitalRead',
  ClinicaCertificadoDigitalUpdate = 'ClinicaCertificadoDigitalUpdate',
  FatorDeRiscoCreate = 'FatorDeRiscoCreate',
  FatorDeRiscoDelete = 'FatorDeRiscoDelete',
  EpcEpiReadAll = 'EpcEpiReadAll',
  EpcEpiRead = 'EpcEpiRead',
  EpcEpiCreate = 'EpcEpiCreate',
  EpcEpiUpdate = 'EpcEpiUpdate',
  DocumentosDeRiscosReadAll = 'DocumentosDeRiscosReadAll',
  DocumentosDeRiscosRead = 'DocumentosDeRiscosRead',
  DocumentosDeRiscosCreate = 'DocumentosDeRiscosCreate',
  DocumentosDeRiscosUpdate = 'DocumentosDeRiscosUpdate',
  ConfiguracaoDoExameRead = 'ConfiguracaoDoExameRead',
  ConfiguracaoDoExameCreate = 'ConfiguracaoDoExameCreate',
  ConfiguracaoDoExameUpdate = 'ConfiguracaoDoExameUpdate',
  ConfiguracaoDoExameDelete = 'ConfiguracaoDoExameDelete',
  ESocialReadAll = 'ESocialReadAll',
  ESocialRead = 'ESocialRead',
  ESocialEnvio = 'ESocialEnvio',
  ESocialReadConsulta = 'ESocialReadConsulta',
  ESocialDelete = 'ESocialDelete',
  CategoriasReadAll = '  CategoriasReadAll',
  CategoriasRead = 'CategoriasRead',
  CategoriasCreate = 'CategoriasCreate',
  CategoriasUpdate = 'CategoriasUpdate',
  FabricantesReadAll = 'FabricantesReadAll',
  FabricantesRead = 'FabricantesRead',
  FabricantesCreate = 'FabricantesCreate',
  PeriodicidadeDelete = 'PeriodicidadeDelete',
  RegraExameOcupacionalDelete = 'RegraExameOcupacionalDelete',
  RegraExamePeriodicoDelete = 'RegraExamePeriodicoDelete',
  AgendamentoDelete = 'AgendamentoDelete',
  BloqueioDelete = 'BloqueioDelete',
  EmpresaCertificadoUpdate = 'EmpresaCertificadoUpdate',
  FaturaImpresso = 'FaturaImpresso',
  ServicoFaturavelReadAll = 'ServicoFaturavelReadAll',
  ServicoFaturavelRead = 'ServicoFaturavelRead',
  ServicoFaturavelCreate = 'ServicoFaturavelCreate',
  ServicoFaturavelUpdate = 'ServicoFaturavelUpdate',
  ServicoFaturavelDelete = 'ServicoFaturavelDelete',
  ConfiguracaoFaturamentoReadAll = 'ConfiguracaoFaturamentoReadAll',
  ConfiguracaoFaturamentoRead = 'ConfiguracaoFaturamentoRead',
  ConfiguracaoFaturamentoCreate = 'ConfiguracaoFaturamentoCreate',
  ConfiguracaoFaturamentoUpdate = 'ConfiguracaoFaturamentoUpdate',
  ConfiguracaoFaturamentoDelete = 'ConfiguracaoFaturamentoDelete',
  FaturaPorEmail = 'FaturaPorEmail',
  CboReadAll = 'CboReadAll',
  CboCreate = 'CboCreate',
  CboUpdate = 'CboUpdate',
  CboDelete = 'CboDelete',
  AfastamentoReadAll = 'AfastamentoReadAll',
  AfastamentoRead = 'AfastamentoRead',
  AfastamentoCreate = 'AfastamentoCreate',
  AfastamentoUpdate = 'AfastamentoUpdate',
  AfastamentoDelete = 'AfastamentDeleAfastamentoDelete',
  MotivoDeAfastamentoReadAll = 'MotivoDeAfastamentoReadAll',
  AcompanhamentoReadAll = 'AcompanhamentoReadAll',
  AcompanhamentoRead = 'AcompanhamentoRead',
  AcompanhamentoCreate = 'AcompanhamentoCreate',
  AcompanhamentoUpdate = 'AcompanhamentoUpdate',
  AcompanhamentoDelete = 'AfastamentDeleAcompanhamentoDelete',
  ImportarPlanilha = 'ImportacaoPlanilha',
  OrdemDeServicoReadAll = 'OrdemDeServicoReadAll',
  OrdemDeServicoRead = 'OrdemDeServicoRead',
  OrdemDeServicoCreate = 'OrdemDeServicoCreate',
  OrdemDeServicoUpdate = 'OrdemDeServicoUpdate',
  OrdemDeServicoDelete = 'OrdemDeServicoDelete',
  MarcadorPersonalizadoReadAll = 'MarcadorPersonalizadoReadAll',
  MarcadorPersonalizadoRead = 'MarcadorPersonalizadoRead',
  MarcadorPersonalizadoCreate = 'MarcadorPersonalizadoCreate',
  MarcadorPersonalizadoUpdate = 'MarcadorPersonalizadoUpdate',
  MarcadorPersonalizadoDelete = 'MarcadorPersonalizadoDelete',
  RiscoPersonalizadoReadAll = 'RiscoPersonalizadoReadAll',
  RiscoPersonalizadoRead = 'RiscoPersonalizadoRead',
  RiscoPersonalizadoCreate = 'RiscoPersonalizadoCreate',
  RiscoPersonalizadoUpdate = 'RiscoPersonalizadoUpdate',
  RiscoPersonalizadoDelete = 'RiscoPersonalizadoDelete',
  LTIPReadAll = 'LTIPReadAll',
  LTIPRead = 'LTIPRead',
  LTIPCreate = 'LTIPCreate',
  LTIPUpdate = 'LTIPUpdate',
  LTIPDelete = 'LTIPDelete',
  LTIPImpresso = 'LTIPImpresso',
  LaudoPericulosidadeReadAll = 'LaudoPericulosidadeReadAll',
  LaudoPericulosidadeRead = 'LaudoPericulosidadeRead',
  LaudoPericulosidadeCreate = 'LaudoPericulosidadeCreate',
  LaudoPericulosidadeUpdate = 'LaudoPericulosidadeUpdate',
  LaudoPericulosidadeDelete = 'LaudoPericulosidadeDelete',
  LaudoPericulosidadeImpresso = 'LaudoPericulosidadeImpresso',
  LaudoInsalubridadeReadAll = 'LaudoInsalubridadeReadAll',
  LaudoInsalubridadeRead = 'LaudoInsalubridadeRead',
  LaudoInsalubridadeCreate = 'LaudoInsalubridadeCreate',
  LaudoInsalubridadeUpdate = 'LaudoInsalubridadeUpdate',
  LaudoInsalubridadeDelete = 'LaudoInsalubridadeDelete',
  LaudoInsalubridadeImpresso = 'LaudoInsalubridadeImpresso',
  ConfiguracoesGeraisCreate = 'ConfiguracoesGeraisCreate',
  ConfiguracoesGeraisUpdate = 'ConfiguracoesGeraisUpdate',
  ConfiguracoesGeraisRead = 'ConfiguracoesGeraisRead',
  ExamesToxicologicosReadAll = 'ExamesToxicologicosReadAll',
  ExamesToxicologicosRead = 'ExamesToxicologicosRead',
  ExamesToxicologicosCreate = 'ExamesToxicologicosCreate',
  ExamesToxicologicosUpdate = 'ExamesToxicologicosUpdate',
  ExamesToxicologicosDelete = 'ExamesToxicologicosDelete',
  ExamesToxicologicosImpresso = 'ExamesToxicologicosImpresso',
}

export default PermissoesPadrao;
