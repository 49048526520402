var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{'section-divider': _vm.sectionDividerPicker }},[(_vm.title)?_c('h4',{class:{'section-title': _vm.sectionDividerPicker }},[_vm._v(_vm._s(_vm.title)+"   "),(_vm.showInfo)?_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({
                           customClass: 'popoveer',
                           title: 'Informação',
                           content: _vm.textoInfo,
                           boundary: 'viewport',
                           placement: 'top'
        }),expression:"{\n                           customClass: 'popoveer',\n                           title: 'Informação',\n                           content: textoInfo,\n                           boundary: 'viewport',\n                           placement: 'top'\n        }",modifiers:{"hover":true}}],staticClass:"h5 text-primary text-left fas fa-circle-info"}):_vm._e()]):_vm._e(),_c('b-row',{class:{ 'mt-2': _vm.sectionDividerPicker }},[_c('b-col',[_c('soi-picker',{attrs:{"name":_vm.name,"label":_vm.label,"title":_vm.title,"placeholder":_vm.placeholder,"multiselect":_vm.multiselect,"pagedTable":_vm.pagedTable,"value":_vm.value,"modalFields":_vm.modalFields,"modalItems":_vm.modalItems,"disabled":_vm.disabled,"loading":_vm.loading},on:{"change":function($event){return _vm.$emit('change', $event)},"fetchData":function($event){return _vm.$emit('fetchData', $event)}},scopedSlots:_vm._u([_vm._l((_vm.modalFields.filter(({ isTemplate }) => isTemplate)),function({ key }){return {key:key,fn:function({ linha: { item } }){return [_vm._t(key,null,{"valor":item})]}}})],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-4"},[(_vm.itensAListar.length)?_c('div',{class:{ 'section-divider': _vm.sectionDividerList }},[_c('h4',{staticClass:"section-title mt-3 mb-4"},[_c('b',[_vm._v(_vm._s(_vm.tituloListagem))])]),_vm._l((_vm.itensAListar),function(item,index){return _c('b-list-group',{key:index},[_c('b-list-group-item',{staticClass:"d-flex justify-content-between mb-2"},[_c('div',{staticClass:"d-flex flex-column w-100 pr-4"},[_c('small',[_vm._v(" "+_vm._s(_vm.exibirItem(item))+" ")])]),_c('soi-button',{staticClass:"text-danger",attrs:{"title":"Remover","icon":"trash-alt","variant":"link","disabled":_vm.disabledExclusao || _vm.permissions.usuarioEmpregador()},on:{"click":function($event){return _vm.excluirItem(item[_vm.chave])}}})],1)],1)})],2):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }