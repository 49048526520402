export const CHANGE_VALUES_CLINIC = '@clinic/CHANGE_VALUES_CLINIC';
export const CHANGE_ADDRESS_VALUES = '@clinic/CHANGE_ADDRESS_VALUES,';
export const CLEAR_CLINIC = '@clinic/CLEAR_CLINIC';
export const GET_CLINIC = '@clinic/GET_CLINIC';
export const GET_CLINIC_CERTIFICATE = '@clinic/GET_CLINIC_CERTIFICATE';
export const GET_CLINIC_TIMER = '@/clinic/GET_CLINIC_TIMER';
export const GET_ALL_CLINICS = '@clinic/GET_ALL_CLINICS';
export const CREATE_CLINIC = '@clinic/CREATE_CLINIC';
export const UPDATE_CLINIC = '@clinic/UPDATE_CLINIC';
export const UPLOAD_CLINIC_CERTIFICADO_DIGITAL = '@clinic/UPLOAD_CLINIC_CERTIFICADO_DIGITAL';
export const DELETE_CLINIC = '@clinic/DELETE_CLINIC';
export const GET_ALL_COMPANIES_FROM_CLINIC = '@clinic/GET_ALL_COMPANIES_FROM_CLINIC';
export const GERA_BACKUP = '@clinic/GERA_BACKUP';
export const DOWNLOAD_BACKUP = '@clinic/DOWNLOAD_BACKUP';

