import axios from 'axios';

import Navigation from '../helpers/NavigationHelper';
import { getToken, removeToken } from '../utils/token';


const api = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token) {
    /* eslint-disable-next-line no-param-reassign */
    config.headers!.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status } = error.response;
      const { url } = error.response.config;

      if (url !== 'login' && status === 401) {
        removeToken();
        window.localStorage.setItem('IsLogged', 'false');
        Navigation.navigate('login');
      }
    }
    return Promise.reject(error);
  },
);

export default api;
