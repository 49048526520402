<template>
  <b-modal id="confirmationModal" :visible="visible" centered :title="title" @hide="$emit('hide')">
    <p>{{ text }}</p>
    <template v-slot:modal-footer>
      <b-row class="text-right">
        <soi-button
          label="Cancelar"
          icon="times-circle"
          variant="danger"
          @click="$emit('cancel')"
        />
        <soi-button
          label="Confirmar"
          icon="check-circle"
          variant="success"
          class="ml-2"
          :disabled="disabled"
          @click="$emit('confirm')"
        />
      </b-row>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';

import ComponenteBase from '../ComponenteBase.vue';

@Component
export default class ConfirmationModal extends ComponenteBase {
  @Prop({ type: String, default: '' })
  private title!: string;

  @Prop({ type: Boolean, default: false })
  private visible!: boolean;

  @Prop({ type: String, default: '' })
  private text!: string;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;
}
</script>
