import Endereco, { IEndereco } from '../Endereco/Endereco';

export interface IClinicaModel {
  id?: number;
  nomeUnico: string;
  razaoSocial: string;
  nomeFantasia: string;
  nivelDeAcesso: number;
  ativo: boolean;
  teste: boolean;
  duracaoDoTeste: number | null;
  email: string;
  cnpj?: string;
  cpf?: string;
  endereco?: IEndereco;
  telefoneDDD?: string;
  telefoneNumero?: string;
  logomarcaId?: number;
  logomarcaGuid?: string;
  logomarcaPath: string;
  logomarcaURL: string;
  logomarca?: File | null;
  verificaExistenciaDeCnpj: boolean;
  verificaExistenciaDeCpf: boolean;
  file?: File;
  senhaDoCertificadoDigital?: string;
  nomeDoCertificadoDigital?: string;
  certificadoS3Id?: number;
  testeDataLimite: string;
  limiteDeUsuarios?: number | null;
  temFaturamento: boolean;
  nomeBackup: string;
  exibirFluxoAntigoAgentesNocivos: boolean;
}

export default class ClinicaModel implements IClinicaModel {
  public id?: number;

  public nomeUnico: string;

  public razaoSocial: string;

  public nomeFantasia: string;

  public nivelDeAcesso: number;

  public ativo: boolean;

  public teste: boolean;

  public duracaoDoTeste: number | null;

  public email: string;

  public cnpj?: string;

  public cpf?: string;

  public endereco?: IEndereco;

  public telefoneDDD?: string;

  public telefoneNumero?: string;

  public logomarca?: File | null;

  public logomarcaId?: number;

  public logomarcaGuid?: string;

  public logomarcaURL: string;

  public logomarcaPath: string;

  public verificaExistenciaDeCnpj: boolean;

  public verificaExistenciaDeCpf: boolean;

  public file?: File;

  public senhaDoCertificadoDigital?: string;

  public nomeDoCertificadoDigital?: string;

  public certificadoS3Id?: number;

  public testeDataLimite: string;

  public limiteDeUsuarios?: number | null;

  public temFaturamento: boolean;

  public nomeBackup: string;

  public exibirFluxoAntigoAgentesNocivos: boolean;

  constructor() {
    this.nomeUnico = '';
    this.endereco = new Endereco();
    this.telefoneDDD = '';
    this.telefoneNumero = '';
    this.nomeFantasia = '';
    this.nivelDeAcesso = -1;
    this.razaoSocial = '';
    this.email = '';
    this.ativo = true;
    this.teste = false;
    this.duracaoDoTeste = null;
    this.verificaExistenciaDeCnpj = true;
    this.verificaExistenciaDeCpf = true;
    this.testeDataLimite = '';
    this.logomarcaPath = '';
    this.logomarcaURL = '';
    this.limiteDeUsuarios = null;
    this.logomarca = null;
    this.temFaturamento = false;
    this.nomeBackup = '';
    this.exibirFluxoAntigoAgentesNocivos = false;
  }
}
